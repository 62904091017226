import { upsertUserAndGetId } from '@/api/User'
import type { FormInputField } from '@/components/forms/types'
import { countryOptions, regionLabelKeys, regions } from '@/helpers/CountriesAndRegions'
import { filterEntries, mapEntries } from '@/helpers/DictHelpers'
import { config } from '@/helpers/Environment'
import { languageItem } from '@/helpers/LanguageHelpers'
import { isLoggedIn } from '@/state/Authentication'

export type IdentityFormData = Dict<string | boolean>

export interface AutocompletedAddressData {
  address: string
  city?: string
  state?: string
  zipCode?: string
}

export function builtInFields(): Dict<FormInputField> {
  const t = languageItem('identityForm')

  const email = {
    label: t.email,
    type: 'email',
    required: true,
    autocomplete: 'email',
    maxlength: 254,
  }

  const password = {
    label: t.password,
    type: 'password',
    required: true,
    minlength: 8,
    maxlength: 254,
  }

  const result = {
    first_name: {
      label: t.firstName,
      type: 'text',
      required: true,
      maxlength: 50,
      autocomplete: 'given-name',
    },
    last_name: {
      label: t.lastName,
      type: 'text',
      required: true,
      maxlength: 50,
      autocomplete: 'family-name',
    },
    email: email,
    email_confirm: {
      ...email,
      label: t.emailConfirm,
      mustEqualCaseInsensitive: 'email',
    },
    password: password,
    password_confirm: {
      ...password,
      label: t.passwordConfirm,
      mustEqual: 'password',
    },
  }

  return mapEntries(result, (value, key) => {
    return { ...value, key } as FormInputField
  })
}

export function customFieldsDefaults(): IdentityFormData {
  return mapEntries(configIdentityFields(), (field) => field.default!)
}

export function customFields(answers: IdentityFormData): Dict<FormInputField> {
  return mapEntries(configIdentityFields(), (field, key) => {
    return {
      key,
      labelHtml: field.title,
      required: field.required,
      maxlength: 1000,
      ...customFieldAttributes(field, answers),
    } as FormInputField
  })
}

export function configIdentityFields() {
  return config.custom_identity_fields || {}
}

function customFieldAttributes(field: CustomDataField, answers: IdentityFormData): Partial<FormInputField> {
  const zipCodeFormat: Partial<FormInputField> = {
    type: 'text',
    autocomplete: 'postal-code',
    // The space character (` `) is encoded (`\u0020`).
    pattern: '[A-Za-z0-9]+(\u0020|-)?[A-Za-z0-9]*',
    minlength: 4,
    maxlength: 10,
  }
  const formats: Dict<Partial<FormInputField>> = {
    email: { type: 'email' },
    phone: { type: 'tel', maxlength: 50 },
    zip_code: zipCodeFormat,
    post_code: zipCodeFormat,
    country: { type: 'select', options: countryOptions, showPlaceholderDropdownOption: true },
    state: stateField(answers),
  }

  if (field.enum) {
    return {
      type: field.editor ?? 'options',
      showPlaceholderDropdownOption: true,
      enum: field.enum,
    } as FormInputField
  }

  if (field.type === 'boolean') {
    return { type: 'checkbox', label: field.description }
  }

  if (field.format && formats[field.format]) {
    return formats[field.format]
  }

  return { type: 'text' }
}

function stateField(answers: IdentityFormData): Partial<FormInputField> {
  const country = (answers.country || customFieldsDefaults().country) as string
  const key = regionLabelKeys[country] || 'state'
  const language = languageItem('identityForm')
  const required = regions[country] != null
  return {
    labelHtml: language[key],
    type: 'select',
    options: regions[country],
    required: required,
    disabled: !required,
    showPlaceholderDropdownOption: true,
  }
}

export function upsertGuestAndGetId(data: IdentityFormData) {
  const payload = {
    first_name: data.first_name as string,
    last_name: data.last_name as string,
    email: data.email as string,
    password: data.password as string | undefined,
    additional_info: sanitizeIdentityMeta(data),
  }

  return upsertUserAndGetId(payload, isLoggedIn())
}

/**
 * Ensures built in fields like password, email, first_name and last_name are not included in metadata.
 */
export function sanitizeIdentityMeta(data: IdentityFormData): IdentityFormData {
  const builtIn = new Set(Object.keys(builtInFields()))
  return filterEntries(data, (_, key) => !builtIn.has(key))
}
