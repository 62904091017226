
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TixTime } from '@/TixTime/TixTime'
import type { TTDurationPrecision } from '@/TixTime/helpers'

export interface VDatePickerAttribute {
  dates: Date
  dot?: {
    class: string
  }
  highlight?: {
    class: string
  }
}

/**
 * This is a wrapper component for the 3rd-party
 * v-calendar's DatePicker component
 */
@Component({ name: 'DatePicker' })
export default class extends Vue {
  // Note: use `selectedDate` getter instead of using this directly
  @Prop({ required: true })
  selectedDate: TixTime | null

  @Prop({ default: () => () => [] })
  // eslint-disable-next-line no-unused-vars
  getDateAnnotations: (date: TixTime) => MessageAction[]

  @Prop({ required: true })
  // eslint-disable-next-line no-unused-vars
  getDateStatus: (date: TixTime) => DateAvailabilityStatus

  // Use callback instead of `$emit` because `$emit` doesn't work when used a modal component
  @Prop({ required: true })
  // eslint-disable-next-line no-unused-vars
  onSelect: (date: TixTime) => void

  @Prop({ default: () => new TixTime().subtract(99, 'year').asDateObject() })
  minDate: Date

  @Prop({ default: () => new TixTime().add(99, 'year').asDateObject() })
  maxDate: Date

  @Prop({ required: false })
  columns: number

  /**
   * These are used for creating annotations (dots and highlights).
   * Instead of creating annotations from `minDat` to `maxDate` which
   * can have a performance impact, we only create annotations for visible dates only.
   *
   * These will be set by `@update:from-page` and `@update:to-page` events.
   */
  visibleFromDate: number = new TixTime().startOfMonth().asTimeValue()
  visibleToDate: number = new TixTime().endOfMonth().asTimeValue()

  focusedDate: TixTime | null = null

  mounted() {
    this.makeAccessible()
  }

  makeAccessible() {
    const datePicker = this.$refs.datePicker as any

    const today = new TixTime()
    const dateToFocus =
      this.selectedDate?.asDateObject() ??
      (new TixTime(this.minDate).isAfter(today) ? this.minDate : today.asDateObject())
    datePicker.focusDate(dateToFocus)

    const nextMonthButton = datePicker.$el.querySelector('.vc-arrow.is-right') as HTMLElement
    nextMonthButton.setAttribute('aria-label', 'Next month')
    nextMonthButton.setAttribute('tabindex', '0')

    const prevMonthButton = datePicker.$el.querySelector('.vc-arrow.is-left') as HTMLElement
    prevMonthButton.setAttribute('aria-label', 'Previous month')
    prevMonthButton.setAttribute('tabindex', '0')

    this.makeKeyboardAccessible()
  }

  private makeKeyboardAccessible() {
    const datePicker = this.$refs.datePicker as Vue
    datePicker.$el.addEventListener('keydown', (e: any) => {
      let day = this.focusedDate ?? this.selectedDate ?? new TixTime()
      let moveBy: number = 0
      let moveUnit: TTDurationPrecision = 'day'

      switch (e.code) {
        case 'ArrowLeft':
          moveBy = -1
          moveUnit = 'day'
          break
        case 'ArrowRight':
          moveBy = 1
          moveUnit = 'day'
          break
        case 'ArrowUp':
          moveBy = -1
          moveUnit = 'week'
          break
        case 'ArrowDown':
          moveBy = 1
          moveUnit = 'week'
          break
        default:
          return
      }

      day = day.add(moveBy, moveUnit)
      if (day.isBetween(new TixTime(this.minDate), new TixTime(this.maxDate))) {
        this.focusedDate = day
        // @ts-ignore
        datePicker.focusDate(this.focusedDate.asDateObject())
      }
    })
  }

  get displayedDates(): TixTime[] {
    const dates: TixTime[] = []
    // Two calendar months are always shown on desktop. Pad the visible dates by one month to each side to handle events that only span one calendar month.
    const firstVisibleDate = new TixTime(this.visibleFromDate).subtract(1, 'month')
    const lastVisibleDate = new TixTime(this.visibleToDate).add(1, 'month')

    for (let curr = firstVisibleDate; curr.isBefore(lastVisibleDate); curr = curr.add(1, 'day')) {
      dates.push(curr)
    }
    return dates
  }

  /**
   * Specifies styles/highlights (classnames) and annotations/dots for specific dates.
   *
   * @see https://vcalendar.io/attributes.html
   */
  get vCalendarAttributes(): VDatePickerAttribute[] {
    const result: VDatePickerAttribute[] = []

    // Always annotate today.
    result.push({
      // TODO Use today in the TZ of the _venue_.
      dates: new TixTime().asDateObject(),
      highlight: { class: 'is-today' },
    })

    // Highlight the selected date.
    if (this.selectedDate) {
      result.push({
        dates: this.selectedDate.asDateObject(),
        highlight: { class: 'selected' },
      })
    }

    // Add a dot for each annotation.
    for (const date of this.displayedDates) {
      for (const annotation of this.getDateAnnotations(date)) {
        result.push({
          dates: date.asDateObject(),
          dot: { class: annotation.style },
        })
      }
    }

    return result
  }

  isSoldOut(date: TixTime): boolean {
    return 'sold_out' == this.getDateStatus(date)
  }

  dateToTimeValue(date: Date): number {
    return new TixTime(date).asTimeValue()
  }

  getDayContentClasses(dDate: Date) {
    // TODO Do we need to specify TZ here?
    const date = new TixTime(dDate)
    const result: string[] = []

    if (this.isDateSelected(date)) {
      result.push('selected')
    }

    const status = this.getDateStatus(date)
    result.push(status)

    if (status != 'available') {
      result.push('is-disabled')
    }

    if (date.isBefore(new TixTime(this.minDate)) || date.isAfter(new TixTime(this.maxDate))) {
      result.push('is-disabled')
    }

    return result
  }

  get disabledDates(): Date[] {
    return this.displayedDates
      .filter((date) => 'available' !== this.getDateStatus(date))
      .map((date) => date.asDateObject())
  }

  /**
   * A map of styles to messages. Each style can have multiple messages.
   */
  get uniqueAnnotations(): Array<[string, string[]]> {
    const results: Dict<Set<string>> = {}
    for (const date of this.displayedDates) {
      for (const annotation of this.getDateAnnotations(date)) {
        results[annotation.style] ??= new Set()
        results[annotation.style].add(annotation.message)
      }
    }
    return Object.entries(results).map(([style, messages]) => [style, Array.from(messages)])
  }

  handleFromPageChange(v) {
    // Workaround for an infinite loop bug.
    // When `minDate` and `maxDate` are in the same month and the multi-month view is active,
    // setting the `visibleFromDate` and `visibleToDate` to the first day of month of `@update:from-page`'s payload
    // and last day of the month of `@update:to-page`'s payload will get v-calendar to fire the aforementioned events repeatedly.

    if (new TixTime(this.minDate).isSame(new TixTime(this.maxDate), 'month')) {
      this.visibleFromDate = new TixTime(this.minDate).startOfMonth().asTimeValue()
    } else {
      this.visibleFromDate = new TixTime(`${v.year}-${String(v.month).padStart(2, '0')}`).asTimeValue()
    }
  }

  handleToPageChange(v) {
    // See comment in the `handleFromPageChange()`
    if (new TixTime(this.minDate).isSame(new TixTime(this.maxDate), 'month')) {
      this.visibleToDate = new TixTime(this.maxDate).endOfMonth().asTimeValue()
    } else {
      this.visibleToDate = new TixTime(`${v.year}-${String(v.month).padStart(2, '0')}`).endOfMonth().asTimeValue()
    }
  }

  handleDayClick(day) {
    if (!day.isDisabled) {
      this.onSelect(new TixTime(day.date))
    }
  }

  handleDayMouseDown(day) {
    this.focusedDate = day
  }

  isDateSelected(date: TixTime): boolean {
    return (this.selectedDate && this.selectedDate.isSame(date, 'day')) || false
  }

  tooltip(day): string {
    // TODO Do we need to specify the TZ here?

    const date = new TixTime(day.date)
    const labels: string[] = []

    if (new TixTime().isSame(date, 'day')) {
      labels.push('Today')
    }

    if (this.isDateSelected(date)) {
      labels.push('Selected date')
    }

    if (this.isSoldOut(date)) {
      labels.push('Sold out')
    }

    const annotations = this.getDateAnnotations(date)
    if (annotations.length > 0) {
      labels.push(...annotations.map((annotation) => annotation.message))
    }

    return labels.join('. ')
  }

  ariaLabel(day): string {
    const result: string[] = []
    result.push(day.ariaLabel)

    const tooltip = this.tooltip(day)
    if (tooltip) {
      result.push(tooltip)
    }
    return result.join('. ')
  }

  get shouldShowLegend(): boolean {
    if (Object.keys(this.uniqueAnnotations).length > 0) {
      return true
    }
    return this.displayedDates.some((date) => 'sold_out' === this.getDateStatus(date))
  }
}
