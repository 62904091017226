
import SelectGroupQuantities from '@/components/events/SelectGroupQuantities.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { mockDescription, mockSummary } from '@/mocks/mocks'
import { Component, Vue } from 'vue-property-decorator'
import type { LinkedTT, LinkedTG } from '@/api/types/processedEntities'

interface GroupOptions extends LinkedTG {
  showAdvertisedFees: boolean
  showHeaders: boolean
}

const variablePrice = {
  currency_amount_max: '50',
  currency_amount_min: '1',
}

const fees = {
  outside_fees: [
    {
      name: 'fee_fixed_outside',
      fee: '100',
    },
    {
      name: 'fee_percent_outside',
      fee: '100',
    },
  ],
}

const groups = {
  hasSummary: {
    name: 'Summary and Description',
    types: [
      {
        // Empty object, uses default group options
      },
      {
        summary: mockSummary,
      },
      {
        description: mockDescription,
      },
      {
        summary: mockSummary,
        description: mockDescription,
      },
    ],
  },
  mixedPrice: {
    name: 'Mixed Price',
    types: [
      {
        description: mockDescription,
        ...variablePrice,
      },
      {
        description: mockDescription,
      },
    ],
  },
  variablePrice: {
    name: 'Variable Prices',
    types: [
      {
        description: mockDescription,
        ...variablePrice,
      },
      {
        description: mockDescription,
        ...variablePrice,
      },
    ],
  },
  advertisedFees: {
    name: 'Advertised Fees',
    showAdvertisedFees: true,
    types: [
      {
        summary: mockSummary,
        ...fees,
      },
      {
        description: mockDescription,
        ...fees,
      },
    ],
  },
  headers: {
    name: 'Show Headers',
    showHeaders: true,
    types: [
      {
        summary: mockSummary,
      },
      {
        description: mockDescription,
      },
    ],
  },
  headersMixedPrices: {
    name: 'Headers + Mixed Prices',
    showHeaders: true,
    types: [
      {
        summary: mockSummary,
        ...variablePrice,
      },
      {
        description: mockDescription,
      },
    ],
  },
  headersVariablePrices: {
    name: 'Headers + Variable Prices',
    showHeaders: true,
    types: [
      {
        summary: mockSummary,
        ...variablePrice,
      },
      {
        description: mockDescription,
        ...variablePrice,
      },
    ],
  },
  advertisedFeesHeaders: {
    name: 'Headers + Advertised Fees',
    showAdvertisedFees: true,
    showHeaders: true,
    types: [
      {
        summary: mockSummary,
        ...fees,
      },
      {
        description: mockDescription,
        ...fees,
      },
    ],
  },
}

@Component({
  name: 'SelectGroupQuantitiesStory',
  components: { SelectGroupQuantities, FormInput2 },
})
export default class extends Vue {
  get groups(): Partial<GroupOptions>[] {
    return Object.keys(groups).map((groupId) => {
      const group = groups[groupId]
      const types: LinkedTT[] = group.types.map((type, index) => {
        return {
          id: `type-${groupId}-${index}`,
          ticket_group_id: 'group' + groupId,
          name: 'Type Name',
          currency_amount: '10',
          ...type,
        }
      })

      return {
        name: group.name,
        id: 'group' + groupId,
        description: 'Ticket group description',
        hidden_type: 'public_browsable',
        showAdvertisedFees: group.showAdvertisedFees,
        showHeaders: group.showHeaders,
        types,
      }
    })
  }

  get quantities(): TicketTypeQuantities[] {
    return this.groups.map((group) => {
      const result: TicketTypeQuantities = {}

      for (const type of group.types!) {
        result[type.id] = {
          quantity: 0,
          price: '10',
        }
      }

      return result
    })
  }
}
