
import ApplyGiftCards from '@/checkout/components/ApplyGiftCards.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { environment } from '@/helpers/Environment'
import type { LanguageStrings } from '@/language/types'
import type { Stripe, StripeElements } from '@stripe/stripe-js'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'
import { stripeOptions } from '@/checkout/stripe/stripeOptions'
import StripePaymentElement from '@/checkout/components/StripePaymentElement.vue'
import EventBus from '@/helpers/EventBus'
import type { NormalizedThemeOptions } from '@/themeConfig/types'
import { normalizeThemeOptions } from '@/themeConfig/processing'
import type { StripeElementsOptionsMode } from '@stripe/stripe-js/types/stripe-js/elements-group'

@Component({
  name: 'TixPayment',
  components: { FormInput2, ApplyGiftCards, StripePaymentElement },
})
export default class extends Vue {
  @Prop({ required: true })
  value: PaymentMethodName

  @Prop({ default: false })
  processing: boolean

  @Prop({ required: true })
  isModifiable: boolean

  @Prop()
  stripe: Stripe | null

  @Prop({ required: true })
  paymentDueByCreditCard: number

  elements: StripeElements | null = null

  // Options plugin properties.
  t: LanguageStrings['tixPayment']

  mounted() {
    EventBus.$on('theme changed', ({ themeOptions }: { themeOptions: NormalizedThemeOptions }) => {
      this.elements?.getElement('payment')?.destroy()
      this.elements = null
      if (this.stripe) {
        this.$nextTick(() => {
          const options = this.stripeOptions(themeOptions)
          this.elements = this.stripe!.elements(options)
        })
      }
    })
  }

  get giftCardsEnabled() {
    return this.isModifiable && Boolean(environment.web.additional_payment_methods_enabled?.gift_card)
  }

  @Watch('stripe', { immediate: true })
  initializeStripeElements(stripe: Stripe | null) {
    // Wait for Vue to finish mounting the component template before trying to access the elements in it.
    this.$nextTick(() => {
      if (stripe) {
        const themeOptions = normalizeThemeOptions(environment.theme)
        const options = this.stripeOptions(themeOptions)
        this.elements = stripe.elements(options)
      } else {
        this.elements = null
      }

      this.$emit('elementsInitialized', this.elements)
    })
  }

  // Vue does not observe changes to element styles, so this cannot be a computed property (getter).
  // @see https://v2.vuejs.org/v2/guide/components-edge-cases.html#Accessing-Child-Component-Instances-amp-Child-Elements
  stripeOptions(theme: NormalizedThemeOptions): StripeElementsOptionsMode {
    // Uses the <FormInput> and it's <input> element as a style guide/source for the Stripe element style.
    // Can't use ref as the input is in the child component.
    const inputElement = (this.$refs.input as Vue).$el
    const hoverElement = (this.$refs.hover as Vue).$el
    const focusElement = (this.$refs.focus as Vue).$el
    const invalidElement = (this.$refs.invalid as Vue).$el
    const messageElement = this.$refs.message as HTMLElement

    const styles = {
      input: {
        default: window.getComputedStyle(inputElement.querySelector('input[type=text]') as HTMLInputElement),
        hover: window.getComputedStyle(hoverElement.querySelector('input[type=text]') as HTMLInputElement),
        focus: window.getComputedStyle(focusElement.querySelector('input[type=text]') as HTMLInputElement),
        invalid: window.getComputedStyle(invalidElement.querySelector('input[type=text]') as HTMLInputElement),
      },
      label: window.getComputedStyle(inputElement.querySelector('.label') as HTMLInputElement),
      message: window.getComputedStyle(messageElement),
    }

    return stripeOptions(theme, this.paymentDueByCreditCard, styles, this.pmcId)
  }

  @Watch('pmcId')
  updateElements() {
    const themeOptions = normalizeThemeOptions(environment.theme)
    this.elements?.update(this.stripeOptions(themeOptions))
  }

  get pmcId() {
    return this.$store.getters['Cart/stripeFee']?.pmc_id
  }
}
