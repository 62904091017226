import { dispatchEvent } from '@/helpers/DispatchEvent'
import { DirectiveOptions } from 'vue'
import { languageItem } from '@/helpers/LanguageHelpers'

const sameAs: DirectiveOptions = {
  inserted(confirmEl, { value, modifiers }) {
    function compare(a: string, b: string) {
      if (modifiers.anyCase) {
        return a.toLowerCase() === b.toLowerCase()
      } else {
        return a === b
      }
    }

    if (!(confirmEl instanceof HTMLInputElement)) {
      throw new Error('The sameAs directive (v-same-as="") is only compatible with HTML <input> elements.')
    }

    // `value` is the value of the v-same-as="" attribute.
    // E.g. <input v-same-as="VALUE" />
    if (value) {
      const sourceEl = document.querySelector(`input[name=${value}]`) as HTMLInputElement

      const checkValidity = () => {
        if (compare(sourceEl.value, confirmEl.value)) {
          confirmEl.setCustomValidity('')
        } else {
          const language = languageItem('validationMessages')
          const key = `${value}MustMatch`
          confirmEl.setCustomValidity(language[key])
        }
      }

      confirmEl.addEventListener('change', checkValidity)
      sourceEl.addEventListener('change', checkValidity)

      sourceEl.addEventListener('blur', () => {
        if (confirmEl.value) dispatchEvent('validate', confirmEl)
      })
    }
  },
}

export default sameAs
