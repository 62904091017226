import { filterNamespacedProperties } from '@/helpers/DictHelpers'
import { configYml, environment } from '@/helpers/Environment'
import { setObjectPropertyByPath } from '@/helpers/MiscellaneousHelpers'
import language from '@/language'
import type { I18nOverrides } from '@/language/types'
import type { Env } from '@/types/Env'
import deepmerge from 'deepmerge'
import type { LocaleMessages } from 'vue-i18n'

type SellerConfig = Required<Env>['sellerConfig']
export function sellerConfig<Key extends keyof SellerConfig>(name: Key): SellerConfig[Key] {
  return environment.sellerConfig?.[name] ?? environment.web[name]
}

/**
 * @deprecated Replace <TenantContent> with portal strings.
 */
export function getCustomContentBlocks(config?: CustomContentBlock[]): Dict<CustomContentBlock[]> {
  const result: Dict<CustomContentBlock[]> = {}
  if (config) {
    // Get templates that have been overridden in the portal strings database table.
    const overrides = filterNamespacedProperties('block:', environment.portalStrings)
    for (const block of config) {
      block.regions.forEach((region) => {
        if (overrides[block.key]) {
          block.template = `<div>${overrides[block.key]}</div>`
        }
        result[region] = result[region] || []
        result[region].push(block)
      })
    }
  }
  return result
}

/**
 * Gets I18n strings including overrides from portal strings and seller metadata.
 */
export function tenantI18nStrings() {
  const tenantConfigOverrides = configYml.i18nOverrides || {}

  // TODO Add a language column to portal_string table.
  const portalOverrides = parseFlatI18nOverrides(environment.portalStrings, 'en')

  // TODO Move seller overrides to seller_string table or abstract portal_string to be more generic.
  const sellerOverrides = parseFlatI18nOverrides(environment.sellerMeta || {})

  // Ordered overrides; Later items take precedence.
  return [language, tenantConfigOverrides, portalOverrides, sellerOverrides].reduce((a, b) =>
    deepmerge(a, b),
  ) as Dict<LocaleMessages>
}

function parseFlatI18nOverrides(data: Dictionary, language?): I18nOverrides {
  const result = {}
  const overrides = filterNamespacedProperties('i18n:', data)

  Object.entries(overrides).forEach(([path, value]) => {
    setObjectPropertyByPath(result, path, value)
  })

  return language ? { [language]: result } : result
}
