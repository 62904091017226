import { API } from '@/api/API'
import { indexBy } from '@/helpers/IndexHelpers'
import { quantitiesPayload } from '@/helpers/TicketTypeQuantities'

export async function fetchCalendarForTicketGroupIDs(
  eventId: string,
  ticketGroupIds: string[],
): Promise<Dict<EventBasicDateData>> {
  const query = {
    _format: 'extended',
    'ticket_group_id._in': ticketGroupIds.join(','),
  }
  const response = await API.getCached<'calendar'>(`events/${eventId}/calendar`, { query })
  return indexBy('date', response.calendar._data)
}

export async function fetchCalendarForTicketTypes(
  eventId: string,
  quantities: TicketTypeQuantities,
  includePricing: boolean,
): Promise<Dict<EventDateData | EventBasicDateData>> {
  const query = { _format: includePricing ? 'price' : 'extended' }
  const body = quantitiesPayload(quantities)
  const response = await API.post<'calendar'>(`events/${eventId}/calendar`, { query, body })
  return indexBy('date', response.calendar._data)
}
