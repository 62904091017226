
import { Component, Prop, Vue } from 'vue-property-decorator'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { reportFormValidity } from '@/helpers/Validation'
import { apiErrorMessageOrRethrow } from '@/errors/helpers'
import type { LanguageStrings } from '@/language/types'

import { CodeModalCallback } from '@/modals/codeModal'

// TODO Merge/deduplicate with <PromoCodesModal> component.
@Component({
  name: 'CodeModal',
  components: { FormInput2 },
})
export default class CodeModal extends Vue {
  t: LanguageStrings['codeModal']

  @Prop({ required: true })
  message: string

  @Prop({ required: true })
  applyCallback: CodeModalCallback

  error: string | undefined = ''
  code = ''
  loading = false

  onSubmit() {
    this.loading = true
    reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => this.applyCallback(this.code))
      .then(() => this.$modals.close())
      .catch((err) => {
        if (!err.validationError) {
          this.error = apiErrorMessageOrRethrow(err)
        }
      })
      .finally(() => (this.loading = false))
  }
}
