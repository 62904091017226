
import { getEventPayload, triggerViewItemEvent } from '@/checkout/helpers/ga4'
import CartWidget from '@/components/cart/CartWidget.vue'
import FloatingNavigateBack from '@/components/elements/FloatingNavigateBack.vue'
import MembersBanner from '@/components/elements/MembersBanner.vue'
import Subtitle from '@/components/elements/Subtitle.vue'
import TenantContent from '@/components/elements/TenantContent'
import AddOneToCart from '@/components/events/AddOneToCart.vue'
import BuyMembership from '@/components/events/BuyMembership.vue'
import ReserveDateFirst from '@/components/events/ReserveDateFirst.vue'
import ReserveQuantityFirst from '@/components/events/ReserveQuantityFirst.vue'
import ReserveSingleEvent from '@/components/events/ReserveSingleEvent.vue'
import MembersOnly from '@/components/membership/MembersOnly.vue'
import { isAnchor, isUpsell } from '@/helpers/Anchors'
import { portal, environment, configYml } from '@/helpers/Environment'
import { coverImageUrl, imageData } from '@/helpers/ImageHelpers'
import { languageItem } from '@/helpers/LanguageHelpers'
import { getSummary, isTrueish, toKebabCase } from '@/helpers/StringHelpers'
import { isForMembersOnly } from '@/helpers/TicketGroupHelpers'
import { currentMembership, isCurrentMember, purchasedMemberships } from '@/state/Membership'
import store from '@/store/store'
import type { ViewItemPayload } from '@/types/ga4'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { Route } from 'vue-router'
import { fetchEventDetails } from '@/api/Events'
import { isChainedUpsell, isEventAvailable, processNextRoute } from '@/helpers/EventHelpers'
import EventDetailMessages from '@/components/events/EventDetailMessages.vue'
import { openDescriptionModal } from '@/modals/descriptionModal'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'

function loader(to: Route, next): Promise<EventDetails | void> {
  return fetchEventDetails(to.params.id, to.query.tg).catch((e) => {
    if (e.notFoundError) {
      // The `fullPath` gets URL-encoded, but at least it includes the search/query string from the original URL.
      // TODO Work out how to pass on the search/query string without encoding it.
      // TODO Find documentation for params array. Only the object usage of it is documented.
      // @see https://router.vuejs.org/guide/essentials/navigation.html
      next({ name: 'error/404', params: [to.fullPath], replace: true })
    } else {
      throw e
    }
  })
}

/**
 * Handles /event/:id route.
 *
 * Purchase flow flowcharts are in Google Docs.
 * @see https://drive.google.com/file/d/1rPlkpO5Fz5t7JL19AEqzZTfzqGzUdbmg/view?usp=sharing
 */
@Component({
  name: 'EventDetailRoute',
  components: {
    EventDetailMessages,
    MembersBanner,
    BuyMembership,
    ReserveDateFirst,
    ReserveSingleEvent,
    ReserveQuantityFirst,
    AddOneToCart,
    TenantContent,
    MembersOnly,
    CartWidget,
    Subtitle,
    FloatingNavigateBack,
    NavigateBack,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  id: string

  @Prop()
  action: string

  loading = true
  processingSelectionQueryParams = false

  event: EventDetails | null = null

  l = languageItem('reserve')

  mounted() {
    window.addEventListener('scroll', this.scrollHandler)
  }

  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler)
  }

  private scrollHandler() {
    const imgEl: HTMLElement = this.$refs['mobile-image'] as HTMLElement
    // Parallax effect
    const factor = 3
    if (imgEl) {
      imgEl.style.top = `${window.scrollY / factor}px`
    }
  }

  beforeRouteEnter(to: Route, from, next) {
    loader(to, next).then((event) => {
      next((vm) => vm.handleEventDetailsResponse(event))
    })
  }

  beforeRouteUpdate(to, from, next) {
    this.loading = true
    loader(to, next).then((event) => {
      if (!event || !isChainedUpsell(to)) {
        this.handleEventDetailsResponse(event)
        next()
      } else {
        isEventAvailable(event).then((available) => {
          if (available) {
            this.handleEventDetailsResponse(event)
            next()
          } else {
            next(processNextRoute(event, []))
          }
        })
      }
    })
  }

  handleEventDetailsResponse(response: EventDetails | void) {
    if (response) {
      this.event = response
      this.appendPageTitle(this.event.name)
      this.loading = false

      const payload: ViewItemPayload = {
        currency: portal.default_currency_code,
        value: 0,
        items: [getEventPayload(this.event)],
      }
      triggerViewItemEvent(payload)
    }
  }

  get categoryClass() {
    return this.event && toKebabCase(this.event.category)
  }

  get image() {
    return imageData(this.event!, coverImageUrl)
  }

  get membersOnly() {
    const event = this.event!
    return isForMembersOnly(event) || event.ticketGroups.every(isForMembersOnly)
  }

  get user() {
    return store.getters['Member/user']
  }

  get normalizedCategory(): string {
    return this.event!.category.toLowerCase().replace(' ', '')
  }

  get contexts(): Set<string> {
    const result = new Set<string>()

    // @see https://docs.google.com/document/d/10ek7SBc9vb-fX3eQVHFthaA1sFdEOmHEs0oOgHIodsY/edit
    this.event!.ticketGroups.forEach((tg) => result.add('handler:' + tg.handler))

    const oneOnly = this.event!.ticketGroups.every((tg) => tg.handler === 'ledger' || tg.handler === 'donation')
    if (oneOnly) {
      result.add('one-ticket-only')
    }

    if (isAnchor(this.event!)) {
      result.add('anchor')
    }

    if (this.normalizedCategory === 'membership') {
      if (this.event!.ticketGroups.some((tg) => tg.handler === 'membership')) {
        result.add('membership')

        if (isCurrentMember() || this.action === 'renew') {
          result.add('renew')
        }

        if (this.action === 'gift') {
          result.add('gift')
        }
      }

      if (this.event!.ticketGroups.every((tg) => tg.handler === 'codes')) {
        result.add('gift-of-membership')
      }
    }

    return result
  }

  getContextualTitle(): string | void {
    const titles = languageItem('contextualEventTitles')

    if (this.contexts.has('anchor')) {
      return titles.anchor
    } else if (this.contexts.has('handler:ledger')) {
      return titles.giftCard
    } else if (this.contexts.has('handler:donation')) {
      return titles.donation
    } else if (this.contexts.has('gift')) {
      return titles.membershipGift
    } else if (this.contexts.has('renew')) {
      return titles.membershipRenew
    } else if (this.contexts.has('membership')) {
      // Use the membership title only if gift and renew contexts are absent.
      return titles.membership
    }
  }

  get title(): string {
    return this.getContextualTitle() || this.event!.name
  }

  get titleInsideContentColumn() {
    if (this.getContextualTitle()) {
      return configYml.titleInsideContentColumn
    } else {
      return this.opt.eventNameTitlePosition === 'inside content' || configYml.titleInsideContentColumn
    }
  }

  goToNextRoute(reservedTicketGroups: LinkedTG[]) {
    this.$router.push(processNextRoute(this.event!, reservedTicketGroups))
  }

  get hideEventDetails() {
    return isTrueish(this.event!.meta.hide_event_details)
  }

  get isChainedUpsell() {
    return isChainedUpsell(this.$route)
  }

  get anchorRequired(): boolean {
    // Does the cart already have an anchor event in it?
    return isUpsell(this.event!) && store.getters['Cart/anchor'] == null
  }

  get quantityFirst(): boolean {
    const config = this.event!.meta.reserve_flow ?? environment.config.default_reserve_flow
    return config === 'quantity_first'
  }

  get isMembershipEvent() {
    return this.contexts.has('membership')
  }

  get membersBannerClass() {
    return this.isMembershipEvent ? 'mobile' : ''
  }

  get currentMembership() {
    return currentMembership()
  }

  get hasPurchasedMemberships() {
    return purchasedMemberships().length > 0
  }

  get promo(): string | null {
    const template = this.$portalString.event_promo
    if (template && template.length > 0) {
      return template
    } else {
      return null
    }
  }

  get membershipPromo(): string | null {
    const currentPath = this.$router.currentRoute.fullPath
    const baseMembershipRoute = `/events/${environment.web.membership_event_id}`
    const template = this.$portalString.event_membership_promo

    if (!isCurrentMember() && !currentPath.startsWith(baseMembershipRoute) && template && template.length > 0) {
      return template
    } else {
      return null
    }
  }

  get eventSummary(): string {
    return getSummary(this.event!.description, this.event!.summary) || ''
  }

  openDescriptionModal() {
    openDescriptionModal(this.event!.description)
  }

  get expiredMembershipMessage(): string {
    return this.$t('renewMembershipMessage.expiredNoDate') as string
  }
}
