import routes from '@/app/routes'
import { afterCheckoutRoute } from '@/checkout/helpers/completing'
import { automaticLogin } from '@/helpers/Authentication'
import { environment } from '@/helpers/Environment'
import { removeQueryParamPromoCode } from '@/helpers/PreloadPromoCodes'
import { prefixedKeys, removeQueryParam, removeQueryParams } from '@/helpers/QueryStringParameters'
import { isTrueish } from '@/helpers/StringHelpers'
import { openLoginModal } from '@/modals/loginModal'
import store from '@/store/store'
import VueRouter from 'vue-router'

const router = new VueRouter({
  mode: 'history',
  routes,

  // Support seller prefixes.
  base: environment.seller?.slug,

  // @ts-ignore Does this do anything?
  saveScrollPosition: true,

  scrollBehavior(to, from) {
    if (to.hash) {
      return { selector: to.hash }
    }

    // If in an iframe, then tell the parent to scroll to top.
    if (window.self !== window.top) {
      window.parent.postMessage(
        {
          message: 'scrollTop',
        },
        '*',
      )
    }

    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
  },
})

router.afterEach((to, from) => {
  if (to.name !== 'event') {
    store.commit('clearUpsellsChain')
  }
})

// Strip trailing slash from URL
router.beforeEach((to, from, next) => {
  // to.path for the homepage is "/" so ignore that case
  if (to.path.length > 1 && to.path.match(/\/$/) && !to.name?.match(/^error/)) {
    // Original implementation was to clone to object, then update the path (as path is readonly on to)
    // and then call .next with the cloned object.
    // structuredClone is failing on the (to) object, and it looks like we don't need to clone it anyway.
    // we can just update the path and call next passing new path and other properties from the original to object.

    return next({
      path: to.path.substring(0, to.path.length - 1),
      params: to.params,
      query: to.query,
      hash: to.hash,
    })
  }

  return next()
})

// Store the indication to skip upsells from the `expresscheckout` query
// string parameter and strip it from the URL.
// "Upsells" is a good internal name, but is not suitable for customer-facing
// content, including URLs.
router.beforeEach((to, from, next) => {
  if (isTrueish(to.query.expresscheckout)) {
    window.tixAppState.skipUpsells = true
  }
  next(removeQueryParam(to, 'expresscheckout'))
})

/**
 * Automatic login allows members to login directly from links provided by the tenant.
 *
 * These are often used in membership renewal campaign emails, for example.
 */
router.beforeEach((to, from, next) => {
  const credentials: Dict<string> = {
    scan_code: to.query.member_code,
    zip_code: to.query.zip_code,
  }

  // TODO Use filterNamespacedProperties()?
  const prefix = 'login.'
  const names = prefixedKeys(prefix, to.query)

  for (const name of names) {
    const key = name.replace(prefix, '')
    credentials[key] = to.query[name]
  }

  automaticLogin(credentials).then(() => {
    // Wait for automatic login to complete before continuing.
    // This ensures compatibility with the login modal query string parameter; `?login=yes`
    next(
      removeQueryParams(to, [
        ...names,
        // Legacy names of older autologin query parameters.
        'member_code',
        'zip_code',
      ]),
    )
  })
})

// Open the login modal, either on cold route-load or warm route-load.
// For cold boots, beforeResolve() is called after Vue has instantiated.
// router.beforeEach() is called too early for modals to be able to open.
// @see https://router.vuejs.org/guide/advanced/navigation-guards.html#global-resolve-guards
router.beforeResolve((to, from, next) => {
  if (isTrueish(to.query.login) && !store.getters['Member/user']) {
    setTimeout(() => openLoginModal().then(() => null))
  }
  next(removeQueryParam(to, 'login'))
})

// Store any discount code query string parameter and strip it from the URL.
router.beforeEach(removeQueryParamPromoCode)

router.afterEach((to, from) => {
  if (from.name === 'checkout' || from.name === 'manage/checkout') {
    afterCheckoutRoute(to, from)
  }
})

export default router
