
import { openLoginOrGuestDialog } from '@/components/membership/LoginOrGuestDialog.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class LoginOrGuestModal extends Vue {
  mounted() {
    openLoginOrGuestDialog()
  }
}
