import moment from 'moment-timezone'

export type TTDurationPrecision =
  | 'years'
  | 'year'
  | 'months'
  | 'month'
  | 'weeks'
  | 'week'
  | 'days'
  | 'day'
  | 'hours'
  | 'hour'
  | 'minutes'
  | 'minute'
  | 'seconds'
  | 'second'
  | 'milliseconds'
  | 'millisecond'

export type TTStringFormat =
  | 'iso'
  | 'LT'
  | 'YYYY-MM-DD'
  | 'military'
  | 'TIME'
  | 'LT_WITH_ZONE'
  | 'DATE'
  | 'LONG_DATE'
  | 'LONGER_DATE'
  | 'LONG_DATE_TIME'
  | 'SHORT_MINUTES_SECONDS'
  | string

export type TTWeekDay = 0 | 1 | 2 | 3 | 4 | 5 | 6

export function setLocale(locale: string) {
  moment.locale(locale)
}

// TODO Look for a library that handles time-of-day (as distinct from date-time).
// this is used in one place in the app only (TimeOfDayFilters) and is operating on decimal time,
// not very generic - possibly should be moved into the component
// TODO Deduplicate from TixTestTime
export function formatDecimalHour(decimal: number) {
  // Separate out hours and minutes.
  const hours = Math.floor(decimal)
  const minutes = 60 * (decimal % 1)

  // Calculate and format components.
  const h24 = String(hours).padStart(2, '0')
  // '0' hours becomes '12' o clock in 12-hour time.
  const h12 = String(hours % 12 || 12)
  const am = decimal < 12 ? 'am' : 'pm'
  const m = String(minutes).padStart(2, '0')

  // Build formatted times from components.
  const twelve = `${h12}:${m} ${am}`
  return {
    twelve,
    twentyFour: `${h24}${m}`,
    shortTwelve: minutes > 0 ? twelve : `${h12}${am}`,
  }
}
