
import BooleanRadios from '@/components/forms/BooleanRadios.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import ExampleModal from '@/modals/ExampleModal.vue'
import { openModal } from '@/modals/helpers/api'
import { Component, Vue } from 'vue-property-decorator'
import { MobileModalType, DesktopModalType } from '@/modals/helpers/types'

@Component({
  name: 'ModalStory',
  components: { BooleanRadios, FormInput2 },
})
export default class extends Vue {
  desktop: DesktopModalType = 'modal'
  mobile: MobileModalType = 'drawer'
  includeTitle: boolean = true

  desktopOptions = [
    { value: 'modal', label: 'Modal' },
    { value: 'hidden', label: 'Hidden' },
  ]

  mobileOptions = [
    { value: 'full-screen', label: 'Full screen' },
    { value: 'hidden', label: 'Hidden' },
    { value: 'drawer', label: 'Drawer' },
  ]

  created() {
    this.openModal()
  }

  openModal() {
    openModal({
      name: 'modal-story',
      component: ExampleModal,
      title: this.includeTitle ? 'Title' : undefined,
      desktop: this.desktop,
      mobile: this.mobile,
    })
  }
}
