
import { Component, Vue } from 'vue-property-decorator'
import EventDetailErrorMessages from '@/components/events/EventDetailErrorMessages.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { csvToArray } from '@/helpers/StringHelpers'

@Component({
  name: 'CartWidgetStory',
  components: {
    EventDetailErrorMessages,
    FormInput2,
  },
})
export default class extends Vue {
  errorOptions: Partial<{
    code: string
    context: string
    description: string
    status: string
  }> = {}

  expectedErrors: string

  get expectedErrorsArray() {
    return this.expectedErrors ? csvToArray(this.expectedErrors) : null
  }

  get error() {
    if (
      !this.errorOptions.code ||
      !this.errorOptions.context ||
      !this.errorOptions.description ||
      !this.errorOptions.status
    ) {
      return null
    } else {
      return {
        response: {
          data: {
            _count: 1,
            _data: [
              {
                _code: this.errorOptions.code,
                _context: this.errorOptions.context,
                _description: this.errorOptions.description,
              },
            ],
            _status: 'error',
          },
          status: this.errorOptions.status,
        },
      }
    }
  }
}
