import type { LanguageOverrides } from '@/language/types'

const spanish: LanguageOverrides = {
  date: {
    today: 'Hoy',
    tomorrow: 'Mañana',
    previousMonth: 'Més anterior',
    nextMonth: 'Més siguiente',
    months: 'Enero, Febrero, Marzo, Abril, Mayo, Junio, Julio, Agosto, Setiembre, Octubre, Noviembre, Diciembre',
    weekdays: 'Domingo, Lunes, Martes, Miercoles, Jueves, Viernes, Sábado',
    weekdaysShort: 'D, L, M, M, J, V, S',
  },

  dateFormat: {
    long: 'dddd, D \\d\\e MMMM',
  },

  dateTimeFormat: {
    long: 'dddd, D \\d\\e MMMM, h:mm A',
  },

  navigateBack: 'Regresar',

  loader: 'Un momento…',

  formInput: {
    optional: 'opcional',
  },

  completedOrdersRoute: {
    title: 'Su cita ha sido reservada',
  },

  pendingOrdersRoute: {
    title: '<span>Listo!</span> Su pedido ha sido recibido',
    referenceNumber: '<p>Número de referencia</p> {number}',
  },

  creditCard: {
    cardNumber: 'Número de tarjeta',
    cardExpiry: 'Vencimiento',
    cardCvc: 'Verificación',
  },

  applyPromoCodes: {
    applyButtonLabel: 'Aplica',
    applyPromoCodeLabel: 'Aplica otro código promocional',
    applyAnotherPromoCodeLabel: 'Aplica un código promocional',
  },

  reserve: {
    selectDateLabel: 'Seleccione la fecha',
  },

  selectDate: {
    tooltip: 'Seleccione la fecha',
    placeholder: 'Seleccione la fecha',
    buttonTooltip: {
      sold_out: 'Agotado',
      closed: 'Cerrado',
      unreleased: 'Todavía no disponible',
    },
  },

  selectTickets: {
    title: 'Seleccione boletos',
    more: 'Más',
    less: 'Menos',
    freePrice: 'Gratuito',
  },

  selectAvailableSession: {
    title: 'Seleccione la hora',
  },

  validationMessages: {
    emailMustMatch: 'Las direcciónes email deben coincidir',
  },

  tixPayment: {
    title: 'Información del pago',
  },

  cartWidget: {
    title: 'Resumen del Pedido',

    subtotal: 'Subtotal',
    payments: 'Pagos',
    total: 'Total del Pedido',

    emptyCartMessage: 'Su carrito está vacío.',
  },
}

export default spanish
