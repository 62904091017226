
import SelectSession from '@/components/events/SelectSession.vue'
import { Component, Vue } from 'vue-property-decorator'
import { mockSession } from '@/mocks/mocksWithTime'
import FormInput2 from '@/components/forms/FormInput2.vue'

@Component({
  name: 'SelectSessionStory',
  components: { SelectSession, FormInput2 },
})
export default class extends Vue {
  selected = null

  unselected = null

  messageRules: MessageRule[] = [
    {
      id: '1',
      _rank: 1,
      portal_id: '',
      seller_id: '',
      triggers: {
        date_or_session: [
          {
            date_range: {
              start: '2000-01-01',
              end: '2100-01-01',
            },
          },
        ],
      },
      actions: {
        session_annotation: {
          message: 'Peak Pricing',
          style: 'info',
        },
      },
    },
    {
      id: '2',
      _rank: 2,
      portal_id: '',
      seller_id: '',
      triggers: {
        date_or_session: [
          {
            date_range: {
              start: '2000-01-01',
              end: '2100-01-01',
            },
          },
        ],
      },
      actions: {
        session_annotation: {
          message: 'On Sale',
          style: 'promo',
        },
      },
    },
  ]

  event = {
    id: '123',
    ticketGroups: [
      {
        id: '456',
        types: [{ name: 'Admission' }],
      },
    ],
    ticketTypes: [
      {
        currency_amount: 10,
      },
      {
        currency_amount: 5,
      },
    ],
    meta: {},
    config: {},
    venue: {
      timezone: 'Pacific/Auckland',
    },
    messageRules: this.messageRules,
  }

  sessions = [
    mockSession(10, { price: 0 }),
    mockSession(11, { availableCapacity: 10 }),
    mockSession(12, { sold_out: true }),
    mockSession(13, { sold_out: true }), // Also conflicts
    mockSession(14), // Conflicts
    mockSession(15),
    mockSession(16),
    mockSession(17),
    mockSession(18, { price: 0, minPrice: 0, maxPrice: 0 }),
    mockSession(19),
    mockSession(20, { availableCapacity: 10 }),
    mockSession(21),
    mockSession(22),
    mockSession(23),
  ]
}
