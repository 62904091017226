
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LanguageStrings } from '@/language/types'
import { API } from '@/api/API'
import store from '@/store/store'
import { firstEntity } from '@/api/Helpers'
import { TixTime } from '@/TixTime/TixTime'
import { getLogoExternalUrl } from "@/helpers/Environment";
import { isLoggedIn } from "@/state/Authentication";

export type AutoRenewState = null | 'scheduled' | 'complete' | 'cancelled' | 'failed' | 'processing'

interface MembershipDetails {
  level: string
  validTo: TixTime
  autoRenewState: AutoRenewState
}

@Component({ name: 'CancelAutoRenewRoute' })
export default class extends Vue {
  @Prop({ required: true })
  id: string

  initialisationError: string | null = null
  initialisationWarning: string | null = null
  error: string | null = null
  confirmMessage: string | null = null

  cancelled = false
  loading = true

  t: LanguageStrings['cancelAutoRenewRoute']

  created() {
    this.fetchMembershipDetails()
      .then((details) => {
        const state = details.autoRenewState
        if (state !== 'scheduled' && state !== 'cancelled') {
          if (isLoggedIn()) {
            this.initialisationWarning = this.t.loggedInUnEnrolledWarningMessage
          } else {
            this.initialisationWarning = this.t.unEnrolledWarningMessage
          }
          return
        }

        this.cancelled = details.autoRenewState === 'cancelled'
        this.confirmMessage = this.$t('cancelAutoRenewRoute.confirmMessage', {
          level: details.level,
          valid_to: details.validTo.format('MMMM D, YYYY'),
        }) as string
      })
      .catch((err) => {
        this.initialisationError = err.message
      })
      .finally(() => {
        this.loading = false
      })
  }

  async fetchMembershipDetails(): Promise<MembershipDetails> {
    return API.getUncached(`ticket_order/${this.id}`, {
      embed: 'ticket, ticket_group, membership',
      query: {
        'ticket.state._in': 'booked, redeemed',
        'ticket_group.handler': 'membership',
      },
    }).then((res) => {
      const [ membership, ticket, group ] = firstEntity(res, 'membership', 'ticket', 'ticket_group')
      return {
        level: group.name,
        validTo: new TixTime(ticket.valid_to),
        autoRenewState: membership.auto_renew_state,
      }
    })
  }

  cancelAutoRenew() {
    this.error = ''

    API.delete(`ticket_order/${this.id}/auto_renew`)
      .then(() => {
        this.cancelled = true

        if (isLoggedIn()) {
          // Reload membership from API.
          store.dispatch('Member/load')
        }
      })
      .catch((err) => {
        this.error = err.message
      })
  }

  goHome() {
    const externalUrl = getLogoExternalUrl()
    if (externalUrl) {
      window.location.href = externalUrl
    } else {
      this.$router.push('/')
    }
  }
}
