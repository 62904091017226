
import Component from 'vue-class-component'
import { Prop, Vue } from 'vue-property-decorator'
import MembersBanner from '@/components/elements/MembersBanner.vue'
import { languageItem } from '@/helpers/LanguageHelpers'

@Component({
  name: 'EventDetailMessages',
  components: { MembersBanner },
})
export default class extends Vue {
  @Prop({ required: true })
  showAddOnBanner: boolean

  @Prop({ default: '' })
  membersBannerClass: string

  @Prop({ default: '' })
  wrapperClass: string

  get l() {
    return languageItem('reserve')
  }
}
