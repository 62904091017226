
import { Component, Vue } from 'vue-property-decorator'
import { openCartExpiryModal } from '@/modals/cartExpiryModal'

@Component({})
export default class CartExpiryModalStory extends Vue {
  show: boolean = false

  mounted() {
    openCartExpiryModal(this.$t('cartExpire')['title'])
  }
}
