<template>
  <select v-model="input" @blur="onBlur" @invalid.prevent="onInvalid">
    <option disabled value="">Select a State</option>
    <option v-for="state in states" :key="state.abbreviation" :value="state.abbreviation">{{ state.name }}</option>
  </select>
</template>

<script>
  import * as UsaStates from 'usa-states'

  /**
   * @deprecated Use <IdentityForm>.
   */
  export default {
    props: {
      value: {
        type: String,
        default: '<none>',
      },
    },

    data() {
      return {
        states: [],
      }
    },

    computed: {
      /**
       * Avoid mutating prop
       *
       * @returns {string}
       */
      input: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        },
      },
    },

    created() {
      const usStates = new UsaStates.UsaStates()
      this.states = usStates.states
    },

    mounted() {
      if (!this.value) {
        this.$el.selectedIndex = -1
      }
    },

    methods: {
      onBlur(e) {
        this.$emit('blur', e)
      },
      onInvalid() {
        this.$emit('invalid')
      },
    },
  }
</script>
