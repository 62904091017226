
import { Component, Prop, Vue } from 'vue-property-decorator'
import { languageItem } from '@/helpers/LanguageHelpers'

@Component({
  name: 'TixStepper',
})
export default class extends Vue {
  l = languageItem('ticketTypeSteppers')

  @Prop({ required: true })
  value: number

  @Prop({ default: 1 })
  nextIncrement: number

  @Prop({ default: 1 })
  nextDecrement: number

  @Prop({ default: 'Increase' })
  increaseButtonLabel: string

  @Prop({ default: 'Decrease' })
  decreaseButtonLabel: string

  @Prop({ required: true })
  countLabel: string

  increment() {
    this.$emit('input', this.value + this.nextIncrement)
  }

  decrease() {
    this.$emit('input', this.value - this.nextDecrement)
  }
}
