
import MobileFooterPortal from '@/components/cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '@/components/cart/ReserveMobileFooter.vue'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import BooleanRadios from '@/components/forms/BooleanRadios.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import IdentityForm from '@/components/forms/IdentityForm.vue'
import Survey2 from '@/components/forms/Survey2.vue'
import MembershipLevel from '@/components/membership/MembershipLevel.vue'
import NamedMembers from '@/components/membership/NamedMembers.vue'
import UnnamedMembers from '@/components/membership/UnnamedMembers.vue'
import { getIdentityFormFields } from '@/helpers/IdentityFormHelpers'
import type { IdentityFormData } from '@/helpers/IdentityHelpers'
import { csvToArray } from '@/helpers/StringHelpers'
import { reportFormValidity } from '@/helpers/Validation'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { anonymousRoles, identifiedRoles, ticketsPayload } from '@/helpers/BuyMembershipHelpers'
import type { MembershipRules } from '@/api/Membership'
import type { LanguageStrings } from '@/language/types'
import { environment } from '@/helpers/Environment'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { logInTrackJS } from '@/errors/helpers'
import ValidationError from '@/errors/ValidationError'
import type { FormInputField } from '@/components/forms/types'

type GifteeHeaderLanguageStrings = LanguageStrings['membershipRoute']['gifteeHeader']

@Component({
  name: 'MembershipForm',
  components: {
    IdentityForm,
    BooleanRadios,
    UnnamedMembers,
    NamedMembers,
    MembershipLevel,
    FormInput2,
    Survey2,
    NavigateBack,
    MobileFooterPortal,
    ReserveMobileFooter,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  event: EventDetails

  @Prop({ required: true })
  ticketGroups: LinkedTG[]

  @Prop({ required: true })
  rules: MembershipRules

  @Prop({ default: () => new Set() })
  contexts: Set<string>

  @Prop({ required: true })
  formId: string

  @Prop()
  previousMembership: { expiry: string; levelName: string } | null

  // Plain text error messages and content that may not be trusted.
  @Prop({ default: null })
  plainError: string | null

  // Templated error messages that may include content like
  //    <membership-link>join now<membership-link> for free tickets.
  @Prop({ default: null })
  templateError: string | null

  @Prop()
  selectedLevel: string | null

  @Prop({ default: false })
  showExtendMembership: boolean

  @Prop()
  showAutoRenew: boolean

  @Prop()
  gifting: boolean

  @Prop({ default: false })
  submitting: boolean

  @Prop({ required: true })
  reserveButtonLabels: {
    desktop: string
    mobile: string
  }

  @Prop()
  autoRenewalLabels: {
    title: string
    subtext: string
    yes: string
    no: string
  }

  @Prop()
  membershipLevelsTitle: {
    label: string
    description: string
  }

  @Prop({ default: {} })
  details: Dict<MembershipAdmitDetails[]>

  @Prop({ default: {} })
  countsByTTID: Dict<number>

  t: LanguageStrings['membershipForm']

  extendMembership = false
  surveyAnswers: Dict<Primitive> = {}
  giftee: IdentityFormData = {}
  autoRenew: boolean = false

  get selectedGroup() {
    return this.ticketGroups.find((group) => group.id === this.selectedLevel)
  }

  get surveySpec() {
    return this.selectedGroup?.additional_info_spec
  }

  get identifiedRoles() {
    return identifiedRoles(this.rules, this.selectedLevel!)
  }

  get anonymousRoles() {
    return anonymousRoles(this.rules, this.selectedLevel!)
  }

  get gifteeHeader(): GifteeHeaderLanguageStrings {
    return this.$t('membershipRoute.gifteeHeader') as GifteeHeaderLanguageStrings
  }

  get gifteeFields(): FormInputField[] {
    if (this.gifting) {
      const omit = csvToArray('first_name, last_name, password, password_confirm, newsletter')
      return getIdentityFormFields(this.giftee, this.identityFormConfigurations, omit)
    } else {
      return []
    }
  }

  get contextsArray() {
    return Array.from(this.contexts)
  }

  submit() {
    this.isSubmitting = true
    this.$emit('update:plainError', null)
    this.$emit('update:templateError', null)

    this.validate()
      .then(() => {
        const tickets = ticketsPayload(this.rules, this.selectedLevel!, this.countsByTTID, this.details)
        if (tickets.length > 0) {
          this.$emit('submit', tickets)
        } else {
          this.$emit('update:plainError', 'At least one member is required to purchase a membership')
          logInTrackJS('Membership requires at least one part', this.selectedLevel)
          return Promise.reject(new ValidationError())
        }
      })
      .catch((error) => {
        this.isSubmitting = false
        // Ignore validation errors and leave the promise resolved.
        if (!error.validationError) {
          throw error
        }
      })
  }

  set isSubmitting(value: boolean) {
    this.$emit('update:submitting', value)
  }

  get isSubmitting(): boolean {
    return this.submitting
  }

  validate() {
    return reportFormValidity(this.$refs.membershipForm as HTMLFormElement)
  }

  get identityFormConfigurations() {
    return [
      this.selectedGroup?.meta.extra_checkout_fields || '',
      this.selectedGroup?.meta.identity_form || '',
      this.event?.meta.identity_form || '',
    ]
  }

  get extendedMembershipOption() {
    if (this.showExtendMembership) {
      return environment.web.extended_membership_option
    } else {
      return null
    }
  }

  get membershipDetailsTitle() {
    return this.gifting ? this.t.gift.title : this.t.title
  }

  get membershipDetailsCaption() {
    return this.gifting ? this.t.gift.caption : this.t.caption
  }

  updateDetails(id: string, value: MembershipAdmitDetails[]) {
    this.$emit('update:details', {
      ...this.details,
      [id]: value,
    })
    const count = value.filter((v) => !v.disabled).length
    this.updateCountsByTTID(id, count)
  }

  updateCountsByTTID(id: string, value: number) {
    this.$emit('update:countsByTTID', {
      ...this.countsByTTID,
      [id]: value,
    })
  }

  @Watch('extendMembership')
  onExtendMembershipChanged() {
    this.$emit('extendMembership', this.extendMembership)
  }

  @Watch('surveyAnswers')
  onSurveyAnswersChanged() {
    this.$emit('surveyAnswers', this.surveyAnswers)
  }

  @Watch('giftee')
  onGifteeChanged() {
    this.$emit('giftee', this.giftee)
  }

  @Watch('autoRenew')
  onAutoRenew() {
    this.$emit('autoRenew', this.autoRenew)
  }
}
