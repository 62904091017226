/**
 * @deprecated TODO Use <FormInput2>.
 */
export default {
  data() {
    return {
      errors: {},
    }
  },

  computed: {
    formElements() {
      return [
        ...Array.from(this.$el.getElementsByTagName('input')),
        ...Array.from(this.$el.getElementsByTagName('select')),
      ]
    },

    isValid() {
      return Object.keys(this.errors).length === 0
    },
  },

  methods: {
    validate() {
      this.formElements.forEach((element) => {
        this.validateField(element)
      })

      this.$emit('validated', this.isValid)
    },

    validateField(element) {
      if (this._validateField) {
        this._validateField(element)
      }

      const isValid = this.updateFieldValidityState(element)

      // If this mixin is used by a component then it can optionally implement
      // an emitter to communicate component valid state to the parent.
      if (this.emitValidity) {
        this.emitValidity()
      }

      return isValid
    },

    updateFieldValidityState(element) {
      /**
       * TODO Originally used id attribute, but it must be unique across the
       * entire page; in progress switching to use name field instead. Then it
       * only needs to be unique per component (or per scope of this validation
       * mixin.)
       * Addendum: duplicate input names in the same form confuses Chrome
       * autofill; use arrayed names in that case, so trim the [] from the end.
       * They names still need to be unique per component.
       */
      const id = element.id || element.name.replace(/\[\]$/, '')

      this.$set(this.errors, id, element.validationMessage)

      // Using the :invalid pseudo class would mean that the input is styled red/invalid as soon
      // as it is displayed on the page, rather than waiting until the input is clicked in/out
      // by the user before displaying the invalid styles.
      // In this onBlur event, we can apply the .invalid class after the input has been
      // interacted with.
      // Ensure is valid HTML element with classList
      if (typeof element.classList === 'object') {
        // TODO Delete this and let the FormInput component set state-based classes.
        // @see FormInput.innerClasses
        element.classList.toggle('invalid', !element.validity.valid)
      } else {
        // Else assuming it is a Stripe element
        element._parent.classList.toggle('StripeElement--invalid', !element.validity.valid)
      }

      if (element.validity.valid) {
        this.$delete(this.errors, id)
      }

      return element.validity.valid
    },

    validateComponent(component) {
      return new Promise((resolve, reject) => {
        component.$once('validated', (isValid) => {
          if (isValid) {
            resolve()
          } else {
            component.$el.scrollIntoView()
            reject(new Error('Failed to validate component.'))
          }
        })

        component.$emit('validate')
      })
    },

    /**
     * WARNING: The returned promise is never fulfilled if any components are invalid.
     *
     * @deprecated Use validateComponents() and handle failed validations in the caller
     */
    validateComponentsWithoutEverRejecting(components, autoScroll) {
      return new Promise((resolve) => {
        this.validateComponents(components, autoScroll)
          .then(resolve)
          // Catch and ignore rejections so that they don't get caught by console or TrackJS.
          // But leave the returned promise forever unfulfilled. 🤦‍
          .catch(() => null)
      })
    },

    /**
     * This is like mixins/Validation.js validateComponent but can handle
     * validation of a set of components together.
     *
     * The returned promise is rejected with the invalid component if any components are invalid.
     *
     * TODO Single-page checkout: Rename to `validateComponents()`.
     *
     * @param components
     * @param autoScroll
     * @returns {Promise<any>}
     */
    validateComponents(components, autoScroll) {
      // Default value forced to boolean
      if (typeof autoScroll === 'undefined') {
        autoScroll = true
      }

      // Validate both components on demand before attempting to continue. If
      // any fail, then jump the scroll to it. It may be narrow width
      // viewport on mobile device so the scroll Y position is important.
      const promises = []

      components.forEach((component) => {
        if (!component) {
          // This covers held carts, where the cart contents is shown in
          // place of the guest details form.
          return
        }

        // Validate a INPUT element
        if (component.tagName && component.tagName === 'INPUT') {
          promises.push(
            new Promise((resolve) => {
              const isValid = this.validateField(component)

              resolve({
                component,
                isValid,
              })
            }),
          )
        } else if (component.$el) {
          promises.push(
            new Promise((resolve) => {
              component.$once('validated', (isValid) => {
                resolve({
                  component,
                  isValid,
                })
              })
            }),
          )

          component.$emit('validate')
        } else {
          throw new Error('Unknown object for validation.')
        }
      })

      return Promise.all(promises).then((results) => {
        const invalidComponent = results.find((result) => !result.isValid)

        if (invalidComponent) {
          if (autoScroll !== false) {
            // Scroll the first failed component into the viewport.
            const element = invalidComponent.component.$el || invalidComponent.component
            element.scrollIntoView()

            // Scroll up a bit more.
            window.scrollBy(0, -30)
          }

          return Promise.reject({ validationError: true })
        } else {
          return Promise.resolve()
        }
      })
    },
  },
}
