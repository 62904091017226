<template>
  <select v-model="input" @blur="onBlur" @invalid.prevent="onInvalid">
    <option v-for="country in countries" :key="country.code" :value="country.code">{{ country.name }}</option>
  </select>
</template>

<script>
  import CountryList from 'country-list'

  export default {
    props: ['value'],

    data() {
      return {
        countries: [],
      }
    },

    computed: {
      /**
       * Avoid mutating prop
       *
       * @returns {string}
       */
      input: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        },
      },
    },

    created() {
      this.countries = CountryList.call(this).getData()
    },

    mounted() {
      if (!this.value) {
        this.$el.selectedIndex = -1
      }
    },

    methods: {
      onBlur(e) {
        this.$emit('blur', e)
      },
      onInvalid() {
        this.$emit('invalid')
      },
    },
  }
</script>
