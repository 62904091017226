import type { EventListItem, EventListItemWithSessions } from '@/api/types/processedEntities'
import { TixTime } from '@/TixTime/TixTime'

export interface AbstractInterval<T> {
  start: T
  end: T
}

export type StringInterval = AbstractInterval<string>
export type TimeInterval = AbstractInterval<TixTime>

// eslint-disable-next-line no-unused-vars
export type EventDetailsPredicate<T extends EventListItem> = (event: T) => boolean

export function predicateForSessionStartsInInterval(
  window: StringInterval,
): EventDetailsPredicate<EventListItemWithSessions> {
  const start = Number(window.start)
  const end = Number(window.end)

  function predicate(event: EventListItemWithSessions) {
    const sessionStartTimes = event.sessions.map((session) => Number(session.startTime.format('military')))
    for (const time of sessionStartTimes) {
      // The end-time of the session does not matter.
      // TODO Consider supporting some padding, perhaps via metadata config.
      if (start <= time && time < end) {
        return true
      }
    }
    return false
  }

  return predicate
}
