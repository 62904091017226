
import FormInput2 from '@/components/forms/FormInput2.vue'
import { languageItem } from '@/helpers/LanguageHelpers'
import { isForFlexibleTickets } from '@/helpers/TicketGroupHelpers'
import type { ComponentOptions } from '@/types/ComponentOptions'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { openModal } from '@/modals/helpers/api'
import { getDateAnnotations } from '@/helpers/DynamicMessages'
import DatePicker from '@/components/elements/DatePicker.vue'
import EventBus from '@/helpers/EventBus'
import { TixTime } from '@/TixTime/TixTime'
import type { EventDetails } from '@/api/types/processedEntities'

type SelectedInput = 'today' | 'tomorrow' | 'datepicker'

/**
 * <SelectDate>'s should handle a different browser timezone
 * and only use the event's timezone for its Vue props and emitted events.
 *
 * It appears to do that, even though VCalandar always just uses the browser timezone.
 *
 * TODO Document how it handles different browser timezone more clearly and add test-automation for it.
 */
@Component({ name: 'SelectDate', components: { FormInput2, DatePicker } })
export default class extends Vue {
  opt: ComponentOptions['selectDate']

  @Prop({ required: true })
  value: TixTime | null

  @Prop({ required: true })
  calendarDates: Dict<EventDateData | EventBasicDateData>

  @Prop({ required: true })
  event: EventDetails

  @Prop({ default: false })
  inline: boolean

  @Prop()
  hideTodayTomorrowButtons: boolean

  selectedInput: SelectedInput | null = null

  isDatePickerOpen = false

  readonly datePickerModalID = 'date-picker-modal'

  mounted() {
    if (this.value) {
      this.selectedInput = 'datepicker'
    }
  }

  get minDate(): TixTime {
    return new TixTime(this.firstAvailableDate).startOfMonth()
  }

  get maxDate(): TixTime {
    let max = new TixTime(this.event.release_sessions_until, this.tz)

    // Hack the TixTime object so that the native JS Date object will
    // be correct in the browser timezone.
    // Eg. Thu May 31 2018 23:59:59 GMT+1200 (NZST)
    // This is technically the wrong absolute date, but for the
    // purpose of a user in the NZST timezone, it has the desired
    // effect of ensuring June 2018 is not shown in the calendar.

    return max.add(max.utcOffset() + new Date().getTimezoneOffset(), 'minutes')
  }

  get language() {
    return languageItem('date')
  }

  get todayTomorrowDates(): Dict<TixTime> {
    const today = new TixTime(null, this.tz)
    const tomorrow = today.add(1, 'day')

    return { today, tomorrow }
  }

  get todayTomorrowButtons(): Array<{ name: string; onClick: Function; status: string }> | null {
    // Hide today/tomorrow buttons if there are any admission passes.
    if (
      this.opt.hideTodayTomorrowButtons ||
      this.hideTodayTomorrowButtons ||
      this.event.ticketGroups.some(isForFlexibleTickets)
    ) {
      return null
    }

    const buttons: SelectedInput[] = ['today', 'tomorrow']

    return buttons.map((name) => ({
      name,
      onClick: () => this.onDateSelected(name, this.todayTomorrowDates[name]),
      status: this.getDateStatus(this.todayTomorrowDates[name]),
    }))
  }

  get dateInputDisplayValue(): string | null {
    if (!this.todayTomorrowButtons) {
      // Show the date in the date-picker <input> as "Today" or "Tomorrow" if there are no today/tomorrow buttons.
      if (this.dateIsSelected(this.todayTomorrowDates.today)) {
        return this.language.today
      } else if (this.dateIsSelected(this.todayTomorrowDates.tomorrow)) {
        return this.language.tomorrow
      }
    }

    return this.selectedInput === 'datepicker' ? this.value!.format(this.longDateFormat) : null
  }

  get firstAvailableDate(): string | undefined {
    const dates = this.calendarDates
    return Object.keys(dates).find((date) => 'available' === dates[date]?.status)
  }

  get pickerMode() {
    return this.inline ? 'inline' : 'popout'
  }

  get longDateFormat() {
    return languageItem('dateFormat').long
  }

  get tz(): string {
    return this.event.venue.timezone
  }

  @Watch('value')
  onValueChange(value) {
    // The parent component passes in null when admission pass is selected
    if (value === null) {
      this.selectedInput = null
    }
  }

  dateIsSelected(date) {
    return this.value?.isSame(date, 'day')
  }

  /**
   * Take a given Moment of browser origin, which can be any time zone, and convert it into a
   * new Moment with the timezone of the venue.
   *
   * This avoids cross-timezone conversion issues. For example, if you click on a calendar date
   * for October 29 (9am), but the browser/system timezone is NZDT, then this date may convert
   * to October 28 (3pm) for a venue in Chicago.
   *
   * Since the calendar seems to always use the browser/system timezone, we need to discard the
   * timezone information and use only the YYYY-MM-DD data to create a new Moment with the
   * appropriate timezone.
   *
   * @todo Is there a way to set/change a Moment object's timezone without making a timezone
   * conversion of the actual time and date?
   */
  venueDate(date: TixTime) {
    return new TixTime(date.format('DATE'), this.tz)
  }

  getDateStatus(date: TixTime): DateAvailabilityStatus {
    if (date.isAfter(this.event.release_sessions_until)) {
      return 'unreleased'
    } else {
      const formatted = date.format('DATE')
      const status = this.calendarDates[formatted]?.status
      return status || 'closed'
    }
  }

  onDateSelected(selectedInput: SelectedInput, date: TixTime) {
    this.selectedInput = selectedInput
    this.$emit('input', date)
  }

  getDateAnnotations(date: TixTime): MessageAction[] {
    return getDateAnnotations(date, this.event)
  }

  openDatePicker() {
    this.isDatePickerOpen = true

    openModal({
      name: 'date-picker-modal',
      title: 'Choose date',
      component: DatePicker,
      onClose: () => (this.isDatePickerOpen = false),
      props: {
        id: this.datePickerModalID,
        selectedDate: this.datePickerValue,
        onSelect: this.handleOnDateSelect,
        minDate: this.minDate.asDateObject(),
        maxDate: this.maxDate.asDateObject(),
        getDateStatus: this.getDateStatus,
        getDateAnnotations: this.getDateAnnotations,
      },
      size: 'lg',
      closeable: true,
    })
  }

  handleOnDateSelect(date: TixTime) {
    this.onDateSelected('datepicker', this.venueDate(date))
    EventBus.$emit('close:modal')
  }

  get datePickerValue(): TixTime | null {
    // Drop the timezone so calendar dates always compare correctly.
    return this.value ? new TixTime(this.value.format('YYYY-MM-DD')) : null
  }
}
