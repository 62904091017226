
import SelectQuantities from '@/components/events/SelectQuantities.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { randomIdentifier } from '@/helpers/StringHelpers'
import { mockTicketGroups } from '@/mocks/mocks'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mockSession } from '@/mocks/mocksWithTime'
import type { Session } from '@/types/Sessions'
import type { LinkedTG } from '@/api/types/processedEntities'

@Component({
  name: 'SelectQuantitiesStory',
  components: { SelectQuantities, FormInput2 },
})
export default class extends Vue {
  value = {}

  tgOptions: Partial<{
    includeChildTicketType: boolean
    includeTTSummary: boolean
    includeTTDescription: boolean
    tgVisibility: HiddenType
    minPerOrder: number
    maxPerOrder: number
    _hardCodedMaxLimit: number
    includeSecondTicketGroup: boolean
    exclude: boolean
    capacity: number
    usedCapacity: number
    oversellCapacity: number
  }> = {}

  sessionAvailableCapacity: number | null = null

  maxQuantity: number | null = null
  limitTicketType: string = 'typeAdultA'

  versionKey: string = randomIdentifier()

  get ticketGroups(): Partial<LinkedTG>[] {
    return mockTicketGroups(this.tgOptions)
  }

  get selectedSession(): null | Partial<Session> {
    const options: Partial<Session> = {}
    if (this.sessionAvailableCapacity) {
      options.availableCapacity = this.sessionAvailableCapacity
    }
    return mockSession(5, options)
  }

  get quantityLimits(): TicketTypeQuantities | null {
    if (this.maxQuantity) {
      const limits = {}
      limits[this.limitTicketType] = {
        quantity: this.maxQuantity,
      }
      return limits
    }
    return null
  }

  /**
   * Force the SelectQuantities component to be re-rendered when config options change
   */
  @Watch('ticketGroups', { deep: true })
  @Watch('sessionOptions', { deep: true })
  @Watch('quantityLimits', { deep: true })
  generateKey() {
    this.versionKey = randomIdentifier()
  }
}
