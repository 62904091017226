
import FormInput2 from '@/components/forms/FormInput2.vue'
import { Component, Vue } from 'vue-property-decorator'
import DatePicker from '@/components/elements/DatePicker.vue'
import { openModal } from '@/modals/helpers/api'
import { TixTime } from '@/TixTime/TixTime'

interface DatePickerAnnotation {
  date: TixTime
  style: MessageStyle
  message: string
}

@Component({
  components: { FormInput2 },
})
export default class DialogWrapperStory extends Vue {
  selected: TixTime | null = this.today

  mounted() {
    openModal({
      name: 'date-picker-modal',
      title: 'Choose date',
      component: DatePicker,
      props: {
        id: 'date-picker-modal',
        minDate: this.today.subtract(3, 'month'),
        selectedDate: this.selected,
        onSelect: (v: TixTime) => (this.selected = v),
        getDateStatus: this.getDateStatus,
        getDateAnnotations: this.getDateAnnotations,
      },
      size: 'lg',
      closeable: true,
    })
  }

  get today() {
    // Have a fixed date as for that so visual regression tests
    return new TixTime('2022/10/05')
  }

  get annotations(): DatePickerAnnotation[] {
    const tomorrow = this.today.add(1, 'day')
    const nextWeek = this.today.add(1, 'week').add(3, 'day')

    return [
      {
        message: 'Peak hour',
        style: 'warning',
        date: tomorrow,
      },
      {
        message: 'Artist on-site',
        style: 'info',
        date: tomorrow,
      },
      {
        message: 'Half-price children',
        style: 'promo',
        date: tomorrow,
      },
      {
        message: 'Construction',
        style: 'warning',
        date: nextWeek,
      },
    ]
  }

  getDateAnnotations(date: TixTime): MessageAction[] {
    return this.annotations
      .filter((item) => date.isSame(item.date, 'day'))
      .map((item) => ({ message: item.message, style: item.style }))
  }

  getDateStatus(date: TixTime): DateAvailabilityStatus {
    const next3Day = this.today.add(3, 'day')
    if (date.isSame(next3Day, 'day')) {
      return 'sold_out'
    } else {
      return 'available'
    }
  }
}
