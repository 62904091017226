import type { LinkedTG, LinkedTT } from '@/api/types/processedEntities'

export function mockTicketGroups(
  options: Partial<{
    includeChildTicketType: boolean
    includeSecondTicketGroup: boolean
    includeTTSummary: boolean
    includeTTDescription: boolean
    tgVisibility: HiddenType
    minPerOrder: number
    maxPerOrder: number
    exclude: boolean
    capacity: number
    usedCapacity: number
    oversellCapacity: number
  }>,
): Partial<LinkedTG>[] {
  return !options.includeSecondTicketGroup
    ? [mockTicketGroup('A', options)]
    : [mockTicketGroup('A', options), mockTicketGroup('B', options)]
}

function mockTicketGroup(
  suffix: string,
  options: Partial<{
    includeChildTicketType: boolean
    includeSecondTicketGroup: boolean
    includeTTSummary: boolean
    includeTTDescription: boolean
    tgVisibility: HiddenType
    minPerOrder: number
    maxPerOrder: number
    exclude: boolean
    capacity: number
    usedCapacity: number
    oversellCapacity: number
  }>,
) {
  const types = [
    {
      id: 'typeAdult' + suffix,
      name: 'Adult',
      currency_amount: '10',
      summary: ticketTypeSummary(options),
      description: ticketTypeDescription(options),
    } as LinkedTT,
  ]

  if (options.includeChildTicketType) {
    types.push({
      id: 'typeChild' + suffix,
      name: 'Child',
      currency_amount: '5',
      summary: ticketTypeSummary(options),
      description: ticketTypeDescription(options),
    } as LinkedTT)
  }

  return {
    id: 'group' + suffix,
    name: 'General Admission',
    description: '',
    hidden_type: options.tgVisibility ?? 'public_browsable',
    max_tickets_per_order: options.maxPerOrder,
    min_tickets_per_order: options.minPerOrder,
    exclude_from_event_capacity: options.exclude,
    capacity: options.capacity ? Number(options.capacity) : -1,
    used_capacity: options.usedCapacity !== undefined ? Number(options.usedCapacity) : undefined,
    oversell_capacity: options.oversellCapacity ? Number(options.oversellCapacity) : 0,
    types: types,
  }
}

export const mockSummary = 'Short description with <b>HTML</b>'
export const mockDescription =
  'Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>. Long description with <b>HTML</b>.'

function ticketTypeSummary(options: { includeTTSummary?: boolean }) {
  if (options.includeTTSummary) {
    return mockSummary
  }
}

function ticketTypeDescription(options: { includeTTDescription?: boolean }) {
  if (options.includeTTDescription) {
    return mockDescription
  }
}
