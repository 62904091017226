
import { Component, Prop, Vue } from 'vue-property-decorator'
import MobileCartButton from '@/components/elements/MobileCartButton.vue'
import Price from '@/components/elements/Price.vue'
import MobileTotal from '@/components/elements/MobileTotal.vue'
import { isChainedUpsell } from '@/helpers/EventHelpers'
import { languageItem } from '@/helpers/LanguageHelpers'

@Component({
  components: { MobileTotal, MobileCartButton, Price },
})
export default class ReserveMobileFooter extends Vue {
  @Prop({ required: true }) formId: string
  @Prop({ required: true }) totalSelectedTickets: number
  @Prop({ required: true }) disabled: boolean
  @Prop({ required: true }) buttonLabel: string
  @Prop() submitting: boolean
  @Prop({ default: '' }) extraButtonClasses: string

  get classes() {
    return [this.extraButtonClasses, { submitting: this.submitting }]
  }

  get showCart() {
    return this.$store.getters['Cart/ticketCount'] > 0
  }

  get showSkip() {
    return isChainedUpsell(this.$route) && this.totalSelectedTickets < 1
  }

  get l() {
    return languageItem('reserve')
  }
}
