
import { fetchCalendarForTicketGroupIDs, fetchCalendarForTicketTypes } from '@/api/calendar'
import { fetchFirstAvailableSession, fetchSessions, fetchSessionsAndPrices } from '@/api/Sessions'
import type { CitypassCouponsPayload } from '@/api/types/payloads'
import MobileFooterPortal from '@/components/cart/MobileFooterPortal.vue'
import ReserveMobileFooter from '@/components/cart/ReserveMobileFooter.vue'
import NavigateBack from '@/components/elements/NavigateBack.vue'
import RenewMembershipMessage from '@/components/elements/RenewMembershipMessage.vue'
import AdmitDetailsFields from '@/components/events/AdmitDetailsFields.vue'
import CitypassCoupons from '@/components/events/CitypassCoupons.vue'
import SelectDate from '@/components/events/SelectDate.vue'
import SelectQuantities from '@/components/events/SelectQuantities.vue'
import SelectSeats from '@/components/events/SelectSeats.vue'
import SelectSession from '@/components/events/SelectSession.vue'
import FormInput from '@/components/forms/FormInput.vue'
import Survey2 from '@/components/forms/Survey2.vue'
import {
  apiErrorMessageOrRethrow,
  cartCodeIsRequired,
  getApiErrorEntity,
  reserveTicketsExpectedErrors,
} from '@/errors/helpers'
import QueryParameterError from '@/errors/QueryParameterError'
import { isAnchor, isUpsell, upsellTicketsMustMatchAnchorTickets } from '@/helpers/Anchors'
import { watchOnce } from '@/helpers/ComponentHelpers'
import { environment } from '@/helpers/Environment'
import { reserveLanguage } from '@/helpers/LanguageHelpers'
import { getQueryParam, NormalizedReserveParamValue } from '@/helpers/QueryStringParameters'
import { getButtonLabel } from '@/helpers/Reserve'
import { getSession, getSessionTicketGroupsAndTypes } from '@/helpers/SessionHelpers'
import { surveyPayload, surveySpecsWithTicketGroupId, SurveySpecWithTicketGroupID } from '@/helpers/SurveyHelpers'
import { isForFlexibleTickets } from '@/helpers/TicketGroupHelpers'
import { createQuantities, quantitiesToMatchAnchor, sumQuantities } from '@/helpers/TicketTypeQuantities'
import { reportFormValidity } from '@/helpers/Validation'
import type { AssignedSeat } from '@/seats/assignments'
import { assignSeatsByTT } from '@/seats/assignments'
import { seatSelectionNeeded } from '@/seats/helpers'
import { deleteCart } from '@/state/Cart'
import { purchasedMemberships } from '@/state/Membership'
import { citypassIsEnabled, setVisitWindow } from '@/state/Miscellaneous'
import { reserveSession } from '@/state/Reserve'
import { CartItem, isTimedItem, TimedItem } from '@/store/CartItem'
import store from '@/store/store'
import { Component, Prop, Watch } from 'vue-property-decorator'
import ReserveBase from './ReserveBase'
import DynamicMessageGroup from '@/components/events/DynamicMessageGroup.vue'
import EventDetailErrorMessages from '@/components/events/EventDetailErrorMessages.vue'
import { TixTime } from '@/TixTime/TixTime'
import { openCodeModal } from '@/modals/codeModal'
import type { AnnotatedSession, Session, SessionsAndPriceSchedules } from '@/types/Sessions'
import type { TimeInterval } from '@/helpers/Intervals'
import type { EventDetails, LinkedTG } from '@/api/types/processedEntities'
import { availableTicketGroups } from '@/helpers/availableTicketGroups'
import { displayPricesOnCalendar, displayPricesOnSessions, sessionsWithPrices } from '@/helpers/PriceDisplay'

interface SelectionQueryParams {
  reserve: NormalizedReserveParamValue
  session?: string
  date?: string
}

/**
 * Selects available tickets starting by date.
 *
 * TODO Extract out support for admission passes & split this into smaller components.
 * @see https://trello.com/c/fYgJ68Px/
 */
@Component({
  name: 'ReserveDateFirst',
  components: {
    DynamicMessageGroup,
    CitypassCoupons,
    SelectQuantities,
    AdmitDetailsFields,
    SelectDate,
    SelectSession,
    SelectSeats,
    NavigateBack,
    FormInput,
    Survey2,
    MobileFooterPortal,
    ReserveMobileFooter,
    RenewMembershipMessage,
    EventDetailErrorMessages,
  },
})
export default class extends ReserveBase {
  @Prop({ required: true })
  event: EventDetails

  @Prop()
  contexts: Set<string>

  readonly formId = 'reserve-date-first-form'

  loading: boolean = true

  // TODO Use false for no error state, like other error data properties?
  initializationWarning: string | null = null
  initializationError: string | null = null

  submitting: boolean = false
  apiError: any = null
  soldOutWarningMessage: string | false = false

  calendarDates: Dict<EventDateData | EventBasicDateData> | null = null
  selectedDate: TixTime | null = null

  sessions: Session[] | null = null
  selectedSession: AnnotatedSession | null = null

  sessionTicketGroups: LinkedTG[] | null = null
  selectedQuantities: TicketTypeQuantities = {}
  limitQuantities: TicketTypeQuantities | null = null

  surveyAnswers: Dict<Dict<Primitive>> = {}

  selectedSeats: AssignedSeat[] | null = null

  // TODO Move to a mixin or composition API component.
  details: Dict<EventAdmitDetails[]> = {}

  // Indicates that flexible tickets are to be reserved instead of standard tickets.
  // Flexible tickets don't have a session. There are two types of flexible tickets:
  //   - Admission passes, that can be redeemed on any session during their valid period.
  //     E.g. season passes, 10-trip passes.
  //   - Discount codes
  flexibleTickets: boolean = false

  // TODO Move to a mixin or composition API component.
  hasCitypassCoupons: boolean = false
  citypassCoupons: string[] = []
  citypassError: string | null = null

  seatMapOpen: boolean = false

  readonly expectedErrors = [
    'invalid_citypass',
    'ticket_group_capacity_exceeded:reserve',
    'ticket_group_sold_out',
    'code_exhausted',
    'session_capacity_exceeded:reserve',
  ]

  l = reserveLanguage('reserveDateFirst')

  mounted() {
    this.setProcessingSelectionQueryParams(this.validSelectionQueryParamsExist)

    this.clearCart().then(() => {
      // Store the cart's initial CityPASS state after deleting any cart (and any CityPASS codes applied to it).
      // This allows the _initial_ state to be used when calculating `get showCitypass()`. It is important that
      // showCitypass is not reactive so that <CitypassCoupons> does not disappear after CityPASS codes are applied
      // to cart and before the user leaves <EventDetailRoute>.
      this.hasCitypassCoupons = this.$store.state.Cart.hasCitypassCoupons
    })

    // Initialize calendar and flexible ticket values in mounted instead of using { immediate: true } on the watcher.
    // Using immediate causes this to run earlier than mounted() in the lifecycle. It caused a bug where
    // this.flexibleTickets is initialized to true, so the flexibleTickets watcher is never triggered and flexible ticket
    // quantities are never initialized. Events with only flexible tickets and no quantity steppers require that
    // the quantities are initialized to preselect 1 ticket.
    this.initializeCalendarDates()
  }

  /**
   * Initialize the availability calendar (or flexible ticket selection).
   *
   * AvailableTGs can change when a user logs in/out, or when the event changes (navigating from one event to another).
   */
  @Watch('event')
  @Watch('purchasedMemberships')
  initializeCalendarDates() {
    this.loading = true
    this.calendarDates = {}
    this.flexibleTickets = false

    if (this.event) {
      if (this.availableTGs.every(isForFlexibleTickets)) {
        this.loading = false
        this.flexibleTickets = true
      } else {
        this.fetchCalendar(this.showPricesOnCalendar)
          .then((response) => {
            this.calendarDates = response
          })
          .then(this.initializeQueryParams)
          .finally(() => {
            this.loading = false
            this.setProcessingSelectionQueryParams(false)

            if (this.isUpsell && !this.selectedDate) {
              this.initializationError = this.l.unavailableOnDate
            }
          })
      }
    }
  }

  fetchCalendar(showPrices: boolean): Promise<Dict<EventDateData | EventBasicDateData>> {
    // When showing prices on the calendar, pass the pricing quantities to the fetchCalendarForTicketTypes
    // otherwise, fetch the calendar for the availableTGIDs.
    return showPrices
      ? fetchCalendarForTicketTypes(this.event.id, this.pricingQuantities, true)
      : fetchCalendarForTicketGroupIDs(this.event.id, this.availableTGIDs)
  }

  get validSelectionQueryParamsExist(): boolean {
    try {
      const params = this.normalizedSelectionQueryParams
      return Boolean(params.date || params.session)
    } catch (error) {
      return false
    }
  }

  get normalizedSelectionQueryParams(): SelectionQueryParams {
    const session = getQueryParam('session')?.value
    const date = getQueryParam('date')?.value
    const reserve = this.normalizedReserveParam

    if (session && date) {
      throw new QueryParameterError('The session and date query parameters can not be used together.')
    }

    return { session, date, reserve }
  }

  setProcessingSelectionQueryParams(value: boolean) {
    this.$emit('update:processingSelectionQueryParams', value)
  }

  private initializeQueryParams(): Promise<void> {
    try {
      return this._initializeQueryParams(this.normalizedSelectionQueryParams)
    } catch (error: any) {
      this.handleLoadParamError(error, 'warn')
      return Promise.resolve()
    }
  }

  private _initializeQueryParams({ date, reserve, session }: SelectionQueryParams): Promise<void> {
    if (date) {
      return new Promise<void>((resolve) => {
        this.handleQueryParamError(this.selectDate(new TixTime(date)))
        resolve()
      }).catch((e) => this.handleLoadParamError(e, reserve))
    } else if (session === 'today') {
      const today = new TixTime(null, this.event.venue.timezone)
      return this.fetchSessionsOnDates(today)
        .then((sessions) => {
          if (sessions.length === 0) {
            throw new QueryParameterError(this.$t('reserve.sessionUnavailable') as string)
          }

          return this.applyQueryParams(sessions[0].id, reserve)
        })
        .catch((e) => this.handleLoadParamError(e, reserve))
    } else if (session === 'next') {
      return fetchFirstAvailableSession(this.event, this.availableTGIDs)
        .then((session) => {
          if (!session) {
            throw new QueryParameterError(this.$t('reserve.sessionUnavailable') as string)
          }

          return this.applyQueryParams(session.id, reserve)
        })
        .catch((e) => this.handleLoadParamError(e, reserve))
    } else if (session) {
      return this.applyQueryParams(session, reserve)
    } else {
      return Promise.resolve()
    }
  }

  private applyQueryParams(sessionID: string, reserve: NormalizedReserveParamValue): Promise<void> {
    const result = getSession(sessionID)
      .catch(() => {
        throw new QueryParameterError(this.$t('reserve.sessionNotFound') as string)
      })
      // Select the given session's (start) date
      .then(({ session, venue }) => this.handleQueryParamError(this.selectDateBySession(session, venue)))
      // Wait for the initial `sessions` list to become available to get the
      // session (ID) given in the URL param
      .then(() => watchOnce(this, 'sessions'))
      .then(() => this.handleQueryParamError(this.selectSessionByID(sessionID)))
      // Wait for the initial `selectedQuantities` to become available to apply the quantities given in the URL param
      .then(() => watchOnce(this, 'selectedQuantities'))
      .then(() => this.handleQueryParamError(this.selectQuantitiesByQueryParams()))
      // Reserve the tickets if specified
      .then(() => {
        if (reserve !== 'false') {
          return this.submit()
        }
      })
      .catch((e) => this.handleLoadParamError(e, reserve))

    return result
  }

  @Watch('calendarDates')
  onCalendarDates(availability) {
    if (store.getters['Cart/uniqueDatesInCart'].length > 1) {
      // Do not set the initial date if there is more than one date in the cart.
    } else if (this.anchor && isTimedItem(this.anchor) && this.anchor.startTime) {
      // Set the date to match the anchor's date, if there is an anchor.
      // Is the event available on the same day as the anchor event?
      const date = this.anchor.startTime.format('YYYY-MM-DD')
      if (availability[date]?.status === 'available') {
        this.selectedDate = this.anchor.startTime
      }
    } else if (this.opt.setInitialDate && this.availableDates.length > 0) {
      // TODO Why does setting the timezone break this?
      this.selectedDate = new TixTime(this.availableDates[0])
    } else if (this.availableDates.length === 1) {
      this.selectedDate = new TixTime(this.availableDates[0])
    }
  }

  @Watch('flexibleTickets')
  onFlexibleTicketsChange() {
    this.resetQuantities(this.event.ticketGroups)

    if (this.flexibleTickets) {
      this.selectedDate = null
    }
  }

  @Watch('selectedDate')
  onDateChange() {
    // Clear the selected session; it is no longer active or displayed after changing date.
    this.selectedSession = null
    this.sessions = null

    // selectedDate is null when clearing the date field.
    if (this.selectedDate) {
      this.flexibleTickets = false

      this.updateSessions()
    }
  }

  @Watch('selectedSession.id')
  // Reset quantities when logging in. A membership may make some available that are not available to guests only.
  @Watch('purchasedMemberships')
  onSessionChange() {
    this.sessionTicketGroups = null
    this.seatMapOpen = false

    if (this.selectedSession) {
      getSessionTicketGroupsAndTypes(this.selectedSession.id, this.availableTGIDs).then((groups) => {
        this.sessionTicketGroups = groups
        // TODO If the customer has touched quantities, should we really be
        // undoing their changes when changing the session?
        this.resetQuantities(groups)
      })
    }
  }
  onSubmit() {
    this.submit()
  }

  submit(codes?: string[]): Promise<void> {
    const hasTicketDateTimeMode = this.event.meta.ticket_date_time !== undefined

    // API does not mark sessions as all-day.
    // Keep track of the all-day sessions for re-use when checking for conflicts or rending the cart.
    if (!hasTicketDateTimeMode && this.isAllDayEvent && this.selectedSession) {
      store.dispatch('Cart/addAllDaySession', this.selectedSession.id)
    }

    this.citypassError = null
    this.apiError = null
    return reportFormValidity(this.$refs.form as HTMLFormElement)
      .then(() => {
        this.submitting = true
      })
      .then(() => {
        const session = this.flexibleTickets ? null : this.selectedSession
        const seats = assignSeatsByTT(this.selectedSeats, this.selectedQuantities, this.event.ticketGroups!)
        const selectedGroupIDs = new Set(this.selectedGroups?.map((g) => g.id))
        return reserveSession(this.selectedQuantities, session, this.details, seats, {
          event: this.event,
          preReservePromoCodes: codes ? { codes } : this.cityPassCouponsPayload,
          additionalInfo: surveyPayload(this.surveyAnswers, selectedGroupIDs),
        })
      })
      .then(() => {
        // Guest passes and admission passes cannot be used as anchor events.
        if (this.isAnchor && !this.flexibleTickets) {
          setVisitWindow(this.sessions!)
        }
      })
      .then(() => {
        this.$emit('done', this.selectedGroups!)
      })
      .catch((error) => {
        if (error.validationError) {
          // Ignore validation errors.
        } else {
          const expected = reserveTicketsExpectedErrors(this.expectedErrors)
          const message = apiErrorMessageOrRethrow(error, expected)

          const apiError = getApiErrorEntity(error)
          if (apiError?._code === 'event_session_sold_out') {
            this.updateSessions().then(() => {
              if (this.sessions!.some((session) => session.availableCapacity > 0)) {
                const session = this.selectedSession?.startTime.format('H:mm A')
                this.soldOutWarningMessage = `Tickets have sold out for the session at ${session}. Please select another session and try again.`
              } else {
                this.initializeCalendarDates()
                const date = this.selectedDate?.format('dddd, MMMM Do YYYY')
                this.soldOutWarningMessage = `Tickets have sold out for ${date}. Please select another date and try again.`
                this.selectedDate = null
              }

              this.selectedSession = null
            })
          } else if (apiError?._code === 'invalid_citypass' || apiError?._code === 'code_exhausted') {
            this.citypassError = message
          } else if (apiError && cartCodeIsRequired(apiError)) {
            openCodeModal(apiError._description, (code) => this.submit([code]))
          } else if (apiError?._code === 'code_not_found') {
            // Throw code_not_found errors so that <CodeModal> can handle it. <CodeModal> re-invokes the submit
            // handler via the second parameter to openCodeModal().
            throw error
          } else {
            this.apiError = error
          }
        }
      })
      .finally(() => {
        this.submitting = false
      })
  }

  get anchor(): TimedItem {
    return store.getters['Cart/anchor']
  }

  get flexibleTicketsAreAvailable(): boolean {
    return this.availableFlexibleTickets.length > 0
  }

  get availableFlexibleTickets() {
    return this.availableTGs.filter(isForFlexibleTickets)
  }

  get showDatePicker(): boolean {
    return (
      !this.isUpsell && this.event.ticketGroups.some(({ handler }) => handler === 'tickets' || handler === 'seated')
    )
  }

  get showSeatSelection(): boolean {
    return (this.selectedSeatedGroups && this.selectedSeatedGroups.length > 0) ?? false
  }

  get isAnchor(): boolean {
    return isAnchor(this.event)
  }

  get isUpsell(): boolean {
    return isUpsell(this.event)
  }

  get showQuantitySelection(): boolean {
    if (this.seatMapOpen) {
      return false
    } else {
      return this.hasSelectedSessionOrFlexibleTickets
    }
  }

  get availableDates(): string[] {
    return Object.entries(this.calendarDates!)
      .filter(([, day]) => day.status === 'available')
      .map(([date]) => date)
  }

  /**
   * "All day" events are just repeating events with a single session that is at least 5 hours long.
   *
   * TODO Abstract this to a helper for global reusability?
   */
  get isAllDayEvent(): boolean {
    if (this.sessions && this.sessions.length === 1 && this.event.event_type === 'repeating') {
      const { startTime, endTime } = this.sessions[0]

      return startTime.durationTo(endTime).asHours() > 5
    } else {
      return false
    }
  }

  get showHurryMessage(): boolean {
    // Only show message if capacity hasn't been configured.
    if (this.capacityThreshold == undefined && this.selectedSession) {
      return this.selectedSession.availableCapacity < 10
    } else {
      return false
    }
  }

  get totalSelectedTickets() {
    return sumQuantities(this.selectedQuantities)
  }

  // TODO: Deduplicate from <ReserveSingleEvent>
  get submitButtonEnabled(): boolean {
    if (this.submitting || this.totalSelectedTickets < 1) {
      return false
    } else if (this.seatSelectionNeeded) {
      return false
    } else {
      return this.hasSelectedSessionOrFlexibleTickets
    }
  }

  get hasSelectedSessionOrFlexibleTickets() {
    return this.flexibleTickets || this.selectedSession != null
  }

  get seatSelectionNeeded() {
    return seatSelectionNeeded(
      this.showSeatSelection,
      this.selectedSeats,
      this.selectedSeatedGroups,
      this.selectedQuantities,
    )
  }

  get ticketGroups(): LinkedTG[] | undefined {
    return this.flexibleTickets
      ? this.availableFlexibleTickets
      : // TODO Should this only show 'tickets' ticket groups?
        this.sessionTicketGroups?.filter((group) => !isForFlexibleTickets(group))
  }

  get ticketGroupsHaveLoaded(): boolean {
    return this.ticketGroups != undefined
  }

  get availableTGs(): LinkedTG[] {
    return availableTicketGroups(this.event.ticketGroups)
  }

  get availableTGIDs(): string[] {
    return this.availableTGs.map((group) => group.id)
  }

  private clearCart() {
    const cartId = store.getters['Cart/cartId']
    if (cartId && this.isAnchor) {
      // Delete any existing cart when loading anchor events.
      return deleteCart(cartId)
    } else {
      return Promise.resolve()
    }
  }

  private resetQuantities(ticketGroups: LinkedTG[]): void {
    const cartItems: CartItem[] = store.getters['Cart/cartItems']
    // Ignore any anchor if this event is already in the cart.
    const anchor = cartItems.some((item) => item.event.id == this.event.id) ? null : this.anchor
    // TODO <SelectQuantities> should set the initial quantities and limits so that <Reserve%> components don't need to.
    this.selectedQuantities = createQuantities(ticketGroups, this.selectedSession, anchor, this.showQuantitySelection)

    if (upsellTicketsMustMatchAnchorTickets(this.event) && this.anchor) {
      // Do not allow more tickets to be booked than what was booked for the anchor event.
      this.limitQuantities = quantitiesToMatchAnchor(this.anchor, ticketGroups, this.showQuantitySelection)
    }
  }

  get showCitypass(): boolean {
    return citypassIsEnabled(this.event) && !this.hasCitypassCoupons && !this.isUpsell
  }

  get pricingQuantities(): TicketTypeQuantities {
    // Assume the TT to use for pricing is the first TT of the first TG.
    // TGs and TTs are already sorted by rank.
    // TODO Allow the pricing TT to be specified in config.
    const pricingType = this.availableTGs[0].types[0]
    return { [pricingType.id]: { quantity: 1 } }
  }

  updateSessions(): Promise<void> {
    return this.fetchSessions().then((sessions) => {
      this.sessions = sessions
    })
  }

  get showPricesOnSessions() {
    return displayPricesOnSessions()
  }

  get showPricesOnCalendar() {
    return displayPricesOnCalendar()
  }

  private fetchSessions(): Promise<Session[]> {
    const visitWindow = store.getters['Cart/visitWindow']
    const onDates = this.isUpsell && visitWindow ? visitWindow : this.selectedDate!
    if (this.showPricesOnSessions) {
      return this.fetchSessionsAndPrices(onDates).then(({ sessions, priceSchedules }) => {
        return sessionsWithPrices(sessions, priceSchedules)
      })
    } else {
      return this.fetchSessionsOnDates(onDates)
    }
  }

  private fetchSessionsOnDates(dates: TixTime | TimeInterval): Promise<Session[]> {
    return fetchSessions(this.event, dates, this.availableTGIDs)
  }

  private fetchSessionsAndPrices(dates: TixTime | TimeInterval): Promise<SessionsAndPriceSchedules> {
    return fetchSessionsAndPrices(this.event, dates, this.pricingQuantities)
  }

  private get cityPassCouponsPayload(): CitypassCouponsPayload | undefined {
    if (this.citypassCoupons.length) {
      return {
        codes: this.citypassCoupons,
        handler: 'citypass',
        handler_data: {
          visit_datetime: this.selectedSession!.start_datetime,
        },
      }
    }

    return undefined
  }

  get purchasedMemberships() {
    return purchasedMemberships()
  }

  get selectedSeatedGroups(): LinkedTG[] | undefined {
    return this.selectedGroups?.filter((group) => group.handler === 'seated')
  }

  get selectedGroups(): LinkedTG[] | undefined {
    return this.ticketGroups?.filter((group) => {
      return group.types.some((type) => this.selectedQuantities[type.id]?.quantity > 0)
    })
  }

  get surveySpecs(): SurveySpecWithTicketGroupID[] {
    return surveySpecsWithTicketGroupId(this.selectedGroups)
  }

  get showSelectionTitle() {
    // Only show the select tickets title if we show date picker, session selector or quantity selection
    return this.showDatePicker || this.selectedDate || this.showQuantitySelection
  }

  get earliestDate() {
    return this.selectedDate ?? new TixTime(this.availableDates[0], this.event.venue.timezone)
  }

  get giftOfMembership() {
    return this.contexts.has('gift-of-membership')
  }

  get buttonLabel() {
    return getButtonLabel(this.flexibleTickets, this.giftOfMembership, this.totalSelectedTickets)
  }

  get capacityThreshold() {
    return environment.web.show_remaining_session_capacity_threshold
  }

  get sessionPickerTabsConfig() {
    return this.event.config.web?.session_picker_tabs
  }
}
