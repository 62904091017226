
import { Component, Vue } from 'vue-property-decorator'
import { openLoginModal } from '@/modals/loginModal'

@Component({})
export default class LoginModalStory extends Vue {
  mounted() {
    openLoginModal()
  }
}
