import type { DateTimeTriggerDetail, EventDetails, LinkedTT, MessageRuleDetail } from '@/api/types/processedEntities'
import { sum } from '@/helpers/MiscellaneousHelpers'
import type { TTWeekDay } from '@/TixTime/helpers'
import { TixTime } from '@/TixTime/TixTime'
import type { AnnotatedSession, Session } from '@/types/Sessions'

export type MessageType = 'date' | 'session' | 'quantities'

export function uniqueAnnotations(sessions: AnnotatedSession[]): MessageAction[] {
  const result = new Set<MessageAction>()
  for (const session of sessions) {
    const annotations = session.annotations ?? []
    for (const annotation of annotations) {
      result.add(annotation)
    }
  }
  return Array.from(result)
}

export function annotateSession(
  session: Session,
  event: EventDetails,
  quantities?: TicketTypeQuantities,
): AnnotatedSession {
  return {
    ...session,
    annotations: getAnnotationsOrMessages(event, 'session', 'annotation', session.startTime, quantities),
  }
}

export function getDateAnnotations(date: TixTime, event: EventDetails, quantities?: TicketTypeQuantities) {
  return getAnnotationsOrMessages(event, 'date', 'annotation', date, quantities)
}

export function getMessages(
  event: EventDetails,
  type: MessageType,
  start?: TixTime | null,
  quantities?: TicketTypeQuantities,
) {
  return getAnnotationsOrMessages(event, type, 'message', start, quantities)
}

function getAnnotationsOrMessages(
  event: EventDetails,
  type: MessageType,
  annotationOrMessage: 'annotation' | 'message',
  start?: TixTime | null,
  quantities?: TicketTypeQuantities,
): MessageAction[] {
  const result: MessageAction[] = []

  const rules: MessageRule[] = event.messageRules ?? []
  for (const rule of rules) {
    const action = getMatchingAction(rule.actions, type, annotationOrMessage)
    if (action && ruleTriggered(event, rule, type, quantities, start)) {
      result.push(action)
    }
  }

  return result
}

function getMatchingAction(
  actions: MessageRule['actions'],
  type: MessageType,
  annotationOrMessage: 'annotation' | 'message',
) {
  if (type === 'quantities') {
    return actions.quantity_message
  } else if (type === 'session') {
    return annotationOrMessage === 'annotation' ? actions.session_annotation : actions.session_message
  } else {
    return annotationOrMessage === 'annotation' ? actions.date_annotation : actions.date_message
  }
}

function ruleTriggered(
  event: EventDetails,
  rule: MessageRule,
  type: MessageType,
  quantities?: TicketTypeQuantities,
  start?: TixTime | null,
): boolean {
  return dateTimePassesTriggers(event, rule, type, start) && quantitiesPassesTriggers(event, rule, quantities)
}

function dateTimePassesTriggers(
  event: EventDetails,
  rule: MessageRuleDetail,
  type: MessageType,
  start?: TixTime | null,
): boolean {
  const triggers = rule.triggers.dateOrSession
  if (triggers == null || triggers.length === 0) {
    return true
  } else if (start == null) {
    return false
  } else {
    return triggers.some((trigger) => {
      // TODO Match by session indices too.
      return (
        matchesDateRange(trigger, start) && matchesDaysOfWeek(trigger, start) && matchesTimeOfDay(trigger, start, type)
      )
    })
  }
}

function matchesDateRange(trigger: DateTimeTriggerDetail, sessionStart: TixTime): boolean {
  if (!trigger.dateInterval) {
    return true
  }

  const { end, start } = trigger.dateInterval
  return sessionStart.isBetween(start, end, undefined, '[)')
}

function matchesDaysOfWeek(trigger: DateTimeTrigger, sessionStart: TixTime): boolean {
  if (!trigger.weekdays) {
    return true
  }

  return trigger.weekdays.includes(sessionStart.getDayOfWeek() as TTWeekDay)
}

function matchesTimeOfDay({ time_of_day }: DateTimeTrigger, sessionStart: TixTime, type: MessageType): boolean {
  if (!time_of_day || type === 'date') {
    return true
  }

  const session = sessionStart.asMinutesSinceMidnight()
  const start = new TixTime(time_of_day.start).asMinutesSinceMidnight()
  const end = new TixTime(time_of_day.end).asMinutesSinceMidnight()
  return session >= start && session < end
}

function quantitiesPassesTriggers(
  event: EventDetails,
  rule: MessageRule,
  quantities: TicketTypeQuantities = {},
): boolean {
  const triggers = rule.triggers.quantity
  if (triggers === undefined || triggers.length === 0) {
    return true
  } else {
    return triggers.every((trigger) => quantitiesMatch(event, trigger, quantities))
  }
}

function ticketTypeQuantitiesMatch(trigger: QuantityTrigger, quantities: TicketTypeQuantities, types: TicketType[]) {
  const matchingTypes = entitiesMatchingTrigger(types, trigger.ticket_type_ids, trigger.ticket_type_names)
  const selectedQuantities = sum(matchingTypes.map((type) => quantities[type.id]?.quantity ?? 0))
  return isWithinBounds(trigger, selectedQuantities)
}

function quantitiesMatch(event: EventDetails, trigger: QuantityTrigger, quantities: TicketTypeQuantities) {
  const types = getRelevantTicketTypes(event, trigger)
  return ticketTypeQuantitiesMatch(trigger, quantities, types)
}

function getRelevantTicketTypes(event: EventDetails, trigger: QuantityTrigger): LinkedTT[] {
  const matchingGroups = entitiesMatchingTrigger(
    event.ticketGroups,
    trigger.ticket_group_ids,
    trigger.ticket_group_names,
  )
  return matchingGroups.flatMap((t) => t.types)
}

function entitiesMatchingTrigger<T extends NamedApiEntity>(
  entities: T[],
  idFilter: string[] | undefined,
  nameFilter: string[] | undefined,
): T[] {
  if (!idFilter?.length && !nameFilter?.length) {
    return entities
  }

  return entities.filter((entity) => {
    return idFilter?.includes(entity.id) || nameFilter?.includes(entity.name)
  })
}

function isWithinBounds({ min, max }: QuantityTrigger, totalSelected: number): boolean {
  const withinMinBounds = min === undefined || totalSelected >= min
  const withinMaxBounds = max === undefined || totalSelected <= max
  return withinMinBounds && withinMaxBounds
}
