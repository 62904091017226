<template>
  <RadioGroup
    v-model="selected"
    :options="radioGroupOptions"
    :error="errors.tier"
    :showMoreLabel="$t('membershipLevel.showMoreLabel')"
    class="membership-level"
  >
    <SectionHeader slot="label" :title="label" :caption="caption" />
  </RadioGroup>
</template>

<script>
  import Validation from '@/plugins/Validation'
  import RadioGroup from '../forms/RadioGroup'

  export default {
    // TODO Remove the validation mixin.
    mixins: [Validation],

    components: { RadioGroup },

    props: {
      ticketGroups: {
        type: Array,
        required: true,
      },
      value: {
        type: String,
        required: false,
      },
      customTitle: {
        type: Object,
        required: false,
        label: {
          type: String,
        },
        description: {
          type: String,
        },
      },
    },

    // TODO Don't store the selected value locally. Let the parent component update the value prop and read it from there.
    // @see https://vuejs.org/v2/guide/components.html#Using-v-model-on-Components
    // @see https://stackoverflow.com/questions/46164374/using-v-model-with-a-prop-on-vue-js
    data() {
      return {
        selected: this.value,
      }
    },

    computed: {
      radioGroupOptions() {
        return this.ticketGroups
          .filter((group) => group.handler === 'membership')
          .map((group) => ({
            value: group.id,
            label: group.name,
            price: group.price,
            originalPrice: group.originalPrice,
            description: group.description,
            summary: group.summary,
          }))
      },
      label() {
        if (this.customTitle) {
          return this.customTitle.label
        } else if (this.title) {
          return this.title.label
        } else {
          return this.$t('membershipLevel.membershipLevel')
        }
      },
      caption() {
        if (this.customTitle) {
          return this.customTitle.description
        }

        return undefined
      },
    },

    created() {
      this.$on('validate', this.validate)
    },

    watch: {
      value() {
        this.selected = this.value
      },

      selected() {
        this.$emit('input', this.selected)
      },
    },
  }
</script>
