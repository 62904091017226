
import CitypassCoupons from '@/components/events/CitypassCoupons.vue'
import SelectGroupQuantities from '@/components/events/SelectGroupQuantities.vue'
import { groupBy, indexItemsById } from '@/helpers/IndexHelpers'
import { sum } from '@/helpers/MiscellaneousHelpers'
import { getPreloadedPromoCodes } from '@/helpers/PreloadPromoCodes'
import { fetchCartMod } from '@/helpers/PromoCodes'
import { getMemberAndGuestStepperGroups, isForMembersOnly, StepperGroups } from '@/helpers/TicketGroupHelpers'
import { quantitiesAreEqual } from '@/helpers/TicketTypeQuantities'
import {
  allowMemberReserveBeyondTerm,
  currentMembership,
  membershipOnDate,
  MembershipSummary,
} from '@/state/Membership'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { exactlyOneTicket } from '@/helpers/Reserve'
import type { Session } from '@/types/Sessions'
import { showAdvertisedFees } from '@/helpers/Fees'
import type { LinkedTG } from '@/api/types/processedEntities'

const { min, max } = Math

/**
 * TODO Split into two components;
 *
 * 1. <SelectQuantities> does not allow quantities to be input by parent component, and would be responsible for;
 *   - Emitting quantities values
 *   - Wrapping <QuantitySteppers>
 *   - Initial quantities
 *   - Passing store anchor to <QuantitySteppers>
 *   - Passing membership limits to <QuantitySteppers>
 *   - Default promo code banner
 *   - CityPASS integration
 *
 * 2. <QuantitySteppers> would be used with v-model as a pure user-input component, and would be responsible for;
 *   - Pure user-input component
 *   - Used with v-model
 *   - Enforcing all limits
 *     - Session capacity
 *     - Overrides on ticket_group configuration; max_tickets_per_order and exclude_from_event_capacity
 *     - Anchor quantities as anchored upsell quantity limits
 *     - Membership
 *   - Handling grouping of ticket groups by members and non-members
 *
 * TODO Come up with better names.
 */
@Component({
  name: 'SelectQuantities',
  components: { CitypassCoupons, SelectGroupQuantities },
})
export default class extends Vue {
  @Prop({ required: true })
  ticketGroups: LinkedTG[]

  @Prop()
  selectedSession: Session

  // Quantities data passed in from the parent.
  @Prop({ required: true })
  value: TicketTypeQuantities

  /**
   * Max limit of tickets available.
   *
   * This is used for anchored upsells.
   */
  @Prop()
  limitQuantities: TicketTypeQuantities | null

  @Prop({ required: true })
  eventTemplateId: string

  @Prop({ required: true })
  sellerId: string

  @Prop({ default: false })
  membersOnly: boolean

  @Prop({ default: false })
  giftOfMembership: boolean

  @Prop() showCitypass: boolean
  @Prop() citypassCoupons: Dictionary
  @Prop() citypassError: string | null
  @Prop() submitting: boolean

  /**
   * @deprecated Only stories & tests should use this, not application code.
   */
  @Prop({ default: 1000 })
  _hardCodedMaxLimit: number

  // Internal quantities data.
  internal: TicketTypeQuantities = {}

  // If a default promo code is set, we may display a banner or show discounted prices.
  // TODO Deprecate banners? Possibly no one uses this feature anymore. How can we check?
  // TODO Move banner support to its own component.
  // `false` indicates that the discount applies to all ticket types.
  discountedTicketTypes: false | Dict<TicketType[]> = false
  banners: CartMod[] = []

  created() {
    // TODO Deprecate banners? Possibly no one uses this feature anymore. How can we check?
    // TODO Move banner support to its own component.
    const promises = getPreloadedPromoCodes().map((code) => fetchCartMod(code))
    if (promises.length > 0) {
      Promise.all(promises).then((responses) => {
        const banners: CartMod[] = []
        const types: TicketType[] = []
        for (const response of responses) {
          banners.push(...response.cartmod._data)
          types.push(...response.ticket_type._data)
        }
        this.banners = banners
        this.discountedTicketTypes = groupBy('id', types)
      })
    }
  }

  // TODO Move banner support to its own component.
  get promoCodeBanners(): CartMod[] {
    if (!this.showPromoCodeBanners) {
      return []
    }

    const identifiers = {
      seller: this.sellerId,
      event_template: this.eventTemplateId,
    }

    return this.banners.filter((banner) => {
      const { resource, resource_id } = banner
      return identifiers[resource] === resource_id
    })
  }

  // TODO Move banner support to its own component.
  private get showPromoCodeBanners(): boolean {
    // Only show promo code banners when either;
    //   1. there is a non-free ticket type that this discount applies to, or
    //   2. the discount applies to all ticket types, and there is a non-free ticket type.
    const groups = this.ticketGroups.filter((tg) => tg.types.some((type) => Number(type.currency_amount) > 0))

    if (this.discountedTicketTypes) {
      // Does the discount apply to any of the ticket groups with non-free ticket types?
      return groups.some((tg) => tg.types.some((type) => this.discountedTicketTypes[type.id]))
    } else {
      // The discount applies to all ticket types. Is there at least one ticket group with non-free ticket types?
      return groups.length > 0
    }
  }

  private get groups(): LinkedTG[] {
    const discount = (id: string): TicketType => {
      return this.discountedTicketTypes?.[id]?.[0] ?? {}
    }

    const result = this.ticketGroups.map((group) => {
      const types = group.types
        // Ignore types with no quantities.
        .filter((type) => this.propLimit(type) > 0)
        // Merge the discounted prices (ticket types) with undiscounted ticket types.
        // TODO Merge all the discounts for this ticket type, not just the first one. How!? 🤔
        // TODO Document why the discount is merged before the undiscounted ticket type.
        // TODO Deprecate banners? Possibly no one uses this feature anymore. How can we check?
        // TODO Move banner support to its own component.
        .map((type) => ({ ...discount(type.id), ...type }))

      return { ...group, types }
    })

    // Ignore groups with no types.
    return result.filter((group) => group.types.length > 0)
  }

  get memberAndGuestStepperGroups(): StepperGroups[] {
    return getMemberAndGuestStepperGroups(this.groups, this.giftOfMembership, this.memberBenefits)
  }

  isMembersTicketGroup(ticketGroup: TicketGroup): boolean {
    return isForMembersOnly(ticketGroup)
  }

  get membership(): MembershipSummary | void {
    if (this.selectedSession && !allowMemberReserveBeyondTerm()) {
      return membershipOnDate(this.selectedSession.startTime)
    } else {
      return currentMembership()
    }
  }

  get memberBenefits(): MemberBenefits | undefined {
    return this.membership?.benefits
  }

  /**
   * If the member ticket limits change due to a different membership active
   * for a different date, update quantities
   */
  @Watch('membership', { deep: true })
  onMembershipChange(newMembership, oldMembership) {
    if (newMembership?.id !== oldMembership?.id) {
      this.updateInternalQuantities()
    }
  }

  @Watch('groups', { deep: true })
  onGroupsChange() {
    this.updateInternalQuantities()
  }

  /**
   * Watch the 'value' prop, and update this.internal to the new value.
   */
  @Watch('value', { deep: true, immediate: true })
  onValueChange(value: TicketTypeQuantities, previous: TicketTypeQuantities) {
    // Avoid infinite loops by only setting quantities when the value has actually changed.
    if (previous && quantitiesAreEqual(this.internal, value)) {
      // The quantities have not changed.
    } else {
      this.updateInternalQuantities()
    }
  }

  updateInternalQuantities() {
    const result: TicketTypeQuantities = {}

    const available = this.remainingAvailableQuantities
    for (const group of this.ticketGroups) {
      for (const type of group.types) {
        const current = this.value[type.id] ?? {}

        result[type.id] = {
          ...current,
          // Ensure the customer cannot select more tickets than what are available.
          quantity: min(current.quantity ?? 0, available[group.id][type.id]),
        }
      }
    }

    this.$set(this, 'internal', result)
  }

  @Watch('internal', { deep: true })
  emitQuantities(quantities: TicketTypeQuantities) {
    this.$emit('input', { ...quantities })
  }

  onQuantityInput({ ticketType, quantity }) {
    this.internal[ticketType.id].quantity += quantity
  }

  onPriceInput({ ticketTypeId, price }: TicketTypeIdAndPrice) {
    this.$set(this.internal[ticketTypeId], 'price', price)
  }

  /**
   * Remaining available ticket quantity for a ticket group's types, accounting for the current selection.
   *
   * "Remaining available" is not the limit. It is the limit, less the currently selected quantity.
   * If any ticket-type stepper is increased to the ticket-group's limit, then all TT steppers in that
   * TG should become disabled.
   */
  get remainingAvailableQuantities(): Dict<Dict<number>> {
    const result: Dict<Dict<number>> = {}

    this.ticketGroups.forEach((group) => {
      const groupAvailability = this.remainingAvailableGroupQuantity(group)

      result[group.id] = {}
      group.types.forEach((type) => {
        const availability = this.remainingAvailableTypeQuantity(group, type)

        // The lower availability applies.
        result[group.id][type.id] = min(availability, groupAvailability)
      })
    })

    return result
  }

  /**
   * Calculate the remaining tickets left based on memberBenefits and the ticket type's name across all groups where
   * member limits apply. Wildcard member benefits are subtracted once all of a named ticket type benefit is depleted.
   */
  private get remainingMemberTicketAvailability(): Dict<number> {
    const result = { ...this.memberBenefits }
    const allTypes = this.groups.filter(this.shouldApplyMemberLimits).reduce((types, group) => {
      return types.concat(group.types)
    }, [] as TicketType[])
    const typesById = indexItemsById(allTypes)

    for (let id in this.internal) {
      const type = typesById[id]
      if (type) {
        const limit = result[type.name] ?? 0
        const remaining = limit - this.getQuantity(type)
        result[type.name] = max(remaining, 0)
        if (remaining < 0) {
          result['*'] += remaining
        }
      }
    }

    return result
  }

  private getMemberRemaining(type: TicketType) {
    const availability = this.remainingMemberTicketAvailability
    return (availability[type.name] ?? 0) + (availability['*'] ?? 0)
  }

  private remainingAvailableGroupQuantity(group: LinkedTG): number {
    const limit = this.groupLimit(group)
    const quantities = group.types.map((type) => this.getQuantity(type))
    const total = sum(quantities)
    return limit - total
  }

  private remainingAvailableTypeQuantity(group: LinkedTG, type: TicketType): number {
    const quantity = this.getQuantity(type)
    const typeRemaining = this.typeLimit(type) - quantity
    const memberRemaining = this.shouldApplyMemberLimits(group) ? this.getMemberRemaining(type) : Infinity
    return min(typeRemaining, memberRemaining)
  }

  private shouldApplyMemberLimits(group: LinkedTG) {
    return this.isMembersTicketGroup(group) || this.membersOnly
  }

  private getQuantity(type): number {
    return this.internal[type.id]?.quantity || 0
  }

  /**
   * Max limit of tickets available for a TicketGroup.
   */
  private groupLimit(group: LinkedTG): number {
    const limits: number[] = []

    if (group.max_tickets_per_order >= 0) {
      limits.push(group.max_tickets_per_order)
    }

    if (group.capacity >= 0) {
      const usedCapacity = group.used_capacity ?? 0
      limits.push(group.capacity + group.oversell_capacity - usedCapacity)
    }

    // Ignore the session limit if the TG is configures not to count towards event capacity.
    // No session has been selected yet when used with <ReserveQuantityFirst>.
    // This limit actually applies to the whole session, not just this group.
    // TODO Introduce an remainingAvailableSessionQuantity move this session capacity limit there.
    if (this.selectedSession && !group.exclude_from_event_capacity) {
      limits.push(this.selectedSession.availableCapacity)
    }

    // The lowest limit applies. With no upper limit.
    return min(Infinity, ...limits)
  }

  private typeLimit(type: TicketType): number {
    // Zero is a valid limit for anchored upsells.
    // The lowest limit applies. Never allow limits greater than 1000 (_hardCodedMaxLimit).
    return min(this._hardCodedMaxLimit, this.propLimit(type))
  }

  private propLimit(type: TicketType): number {
    if (this.limitQuantities) {
      const item = this.limitQuantities[type.id]
      return item ? item.quantity : 0
    } else {
      return Infinity
    }
  }

  get showAdvertisedFees(): boolean {
    return showAdvertisedFees()
  }

  get exactlyOneTicket() {
    return exactlyOneTicket(this.ticketGroups)
  }
}
