
import { Component, Vue } from 'vue-property-decorator'
import TixStepper from '@/components/forms/TixStepper.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'

@Component({
  name: 'StepperStory',
  components: { FormInput2, TixStepper },
})
export default class Foo extends Vue {
  counter: number = 0

  get nextDecrement() {
    return this.counter > 0 ? 1 : 0
  }
}
