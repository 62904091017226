import { notFoundRoute } from '@/errors/helpers'
import { configYml } from '@/helpers/Environment'
import { languageItem } from '@/helpers/LanguageHelpers'
import { getObjectPropertyByPath } from '@/helpers/MiscellaneousHelpers'
import { lowerFirst } from '@/helpers/StringHelpers'
import type { LanguageStrings } from '@/language/types'
import type { MessageFunction } from 'vue-i18n'
import { Component, Vue } from 'vue-property-decorator'

/**
 * @see vue.d.ts
 */
@Component({ name: 'HelpersMixin' })
export default class extends Vue {
  get opt() {
    if (this.key) return getObjectPropertyByPath(configYml.componentOptions ?? {}, this.key as string, {})
    else return {}
  }

  get t() {
    if (this.key) return languageItem(this.key) as Dict<string | Dictionary | MessageFunction>
    else return {}
  }

  private get key(): keyof LanguageStrings | null {
    // TODO Use component name with no transform once all components have type-safety for language strings.
    // @see LanguageStrings
    return this.$options.name ? lowerFirst(this.$options.name) : null
  }

  $notFound() {
    return this.$router.replace(notFoundRoute(this.$route))
  }
}
