<script>
  import FormInput2 from '@/components/forms/FormInput2.vue'
  import { useFlexiblePrice } from '@/helpers/TicketType'
  import { formatCurrencyParts } from '@/helpers/Currency'

  /**
   * TODO Use <RadioGroup> and a single <FormInput> outside of the loop for the custom value?
   */
  export default {
    name: 'TicketTypeRadioItem',

    components: { FormInput2 },

    props: ['type', 'value', 'selectedTypeId'],

    data() {
      return {
        regExp: /^[+]?([1-9][0-9]*)?[0-9](\.[0-9]{1,2})?$/,
        amount: this.$route.query.price || null,
      }
    },

    computed: {
      isSelected() {
        return this.selectedTypeId === this.type.id
      },

      useFlexiblePrice() {
        return useFlexiblePrice(this.type)
      },

      amountParts() {
        return formatCurrencyParts(this.type.currency_amount)
      },
    },

    methods: {
      emit() {
        this.$emit('input', {
          id: this.type.id,
          amount: this.amount,
        })
      },
    },
  }
</script>

<template>
  <div class="ticket-type radio-item-ticket-type">
    <label class="form-input radio-item">
      <input type="radio" name="ticket_type_id" :checked="isSelected" required :value="type.id" @change="emit" />

      <div class="radio-description">
        <span class="label" v-html="type.name" />
        <div v-html="type.description" />
      </div>
    </label>

    <div v-if="(parts = isSelected && useFlexiblePrice && amountParts)" class="custom-value">
      <div class="form-input-prefix currency-symbol">{{ parts[0] }}{{ parts[1] }}</div>

      <div class="form-input number">
        <FormInput2
          label="Value"
          :required="true"
          v-model="amount"
          type="number"
          :min="type.currency_amount_min"
          :max="type.currency_amount_max"
          step="0.01"
          :placeholder="parts[2] + '.' + parts[3]"
          @input="emit"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .radio-item-ticket-type {
    label.radio-item {
      display: flex;

      input[type='radio'] {
        margin-right: 0.5em;
      }

      .label {
        margin: 0;
      }
    }

    .custom-value {
      display: flex;
      align-items: flex-start;
      margin-left: calc(32px);

      .currency-symbol {
        position: relative;
        top: 20px;
      }

      .form-input {
        width: max-content;
      }

      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type='number'] {
        -moz-appearance: textfield;
        width: auto;
      }
    }
  }
</style>
