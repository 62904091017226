
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'CollapsibleWithSummary',
})
export default class extends Vue {
  @Prop({ required: true })
  collapsed: boolean

  $el: HTMLElement

  toggleSummary(el) {
    // Allow room for the absolutely-positioned summary.
    this.$el.style.minHeight = getComputedStyle(el).height
  }
}
