import { environment } from '@/helpers/Environment'
import { getTotalFees, showAdvertisedFees } from '@/helpers/Fees'
import type { Session } from '@/types/Sessions'

export function displayPricesOnCalendar(): boolean {
  return environment.web.price_display?.enabled === 'all'
}

export function displayPricesOnSessions(): boolean {
  return displayPricesOnCalendar() || environment.web.price_display?.enabled === 'sessions_and_tickets'
}

export function sessionsWithPrices(sessions: Session[], priceSchedules: PriceSchedule[]): Session[] {
  return mergeSessionsAndPriceSchedules(sessions, priceSchedules, (session, priceSchedule) => {
    const fees = showAdvertisedFees() ? getTotalFees(priceSchedule?.outside_fees) : 0
    return {
      ...session,
      price: Number(priceSchedule?.currency_amount) + fees,
      maxPrice: Number(priceSchedule?.currency_amount_max),
      minPrice: Number(priceSchedule?.currency_amount_min),
    }
  })
}

export function sessionsWithPriceIncrease(
  sessions: Session[],
  priceSchedules: PriceSchedule[],
  currentScheduledPrice: number,
): Session[] {
  return mergeSessionsAndPriceSchedules(sessions, priceSchedules, (session, priceSchedule) => ({
    ...session,
    priceIncrease: Number(priceSchedule?.currency_amount) - currentScheduledPrice,
  }))
}

function mergeSessionsAndPriceSchedules<T extends Session>(
  sessions: Session[],
  schedules: PriceSchedule[],
  callback: (session: Session, schedule: PriceSchedule) => T,
): Array<Session | T> {
  let i = 0
  return sessions.map((session) => {
    if (session.sold_out) return session
    else {
      // The API guarantees that price schedules contain all _available_ sessions and do not overlap each other.
      while (!session.startTime.isBetween(schedules[i].from, schedules[i].to, undefined, '[)')) {
        i++
      }
      return callback(session, schedules[i])
    }
  })
}
