
import { Component, Prop, Vue } from 'vue-property-decorator'

import { formatCurrencyParts } from '@/helpers/Currency'
/**
 * Renders a price with a tag for each part to allow all sorts of styling.
 */
@Component({
  name: 'Price',
})
export default class extends Vue {
  @Prop({ required: true })
  value

  get parts() {
    return formatCurrencyParts(this.value)
  }

  get currencySign() {
    return this.parts[0]
  }

  get currencySymbol() {
    return this.parts[1]
  }

  get integerPart() {
    return this.parts[2]
  }

  get fractionalPart() {
    // TODO Decimal symbol is not the same in all locales.
    return '.' + this.parts[3]
  }

  get isFree() {
    return this.value === 0
  }
}
