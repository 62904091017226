
import { Component, Vue } from 'vue-property-decorator'
import { openTermsModal } from '@/modals/termsModal'

@Component({})
export default class TermsModalStory extends Vue {
  mounted() {
    openTermsModal(this.$t('termsModal.title') as string)
  }
}
