import { portal } from '../helpers/Environment'

// Also possibly a tenant var
const separator = '—'

export default {
  install(Vue, Router) {
    // This could be something other than tenant name
    // For example, The Broad uses 'The Broad Ticketing' as their leading page title
    // A new var would need to be created
    // TODO Rename to setDocumentTitle()
    Vue.prototype.appendPageTitle = (title) => {
      const elements = [title, separator, portal.name]

      document.title = elements.join(' ')
    }

    Router.beforeEach((to, from, next) => {
      Vue.prototype.appendPageTitle(to.meta.title || portal.name)

      next()
    })
  },
}
