
import SelectAvailableSession from '@/components/events/SelectAvailableSession.vue'
import { conflictingSessions } from '@/helpers/ConflictHelpers'
import { categoriesThatMayConflict, environment } from '@/helpers/Environment'
import { formatSessionList, hideSoldOutSessions } from '@/helpers/SessionHelpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { annotateSession, uniqueAnnotations } from '@/helpers/DynamicMessages'
import DynamicMessageGroup from '@/components/events/DynamicMessageGroup.vue'
import type { AnnotatedSession, Session } from '@/types/Sessions'
import { showAdvertisedFees } from '@/helpers/Fees'
import type { LanguageStrings } from '@/language/types'
import { currencySymbol } from '@/helpers/Currency'
import type { EventDetails, SessionPickerTabsConfig } from '@/api/types/processedEntities'

/**
 * TODO Use this in <ReserveQuantityFirst>.
 */
@Component({
  name: 'SelectSession',
  components: { DynamicMessageGroup, SelectAvailableSession },
})
export default class extends Vue {
  @Prop({ required: true })
  value: AnnotatedSession | null

  @Prop({ required: true })
  sessions: Session[]

  @Prop({ required: true })
  event: EventDetails

  @Prop()
  quantities: TicketTypeQuantities

  @Prop({ default: false })
  isAllDayEvent: boolean

  @Prop({ default: false })
  showPrices: boolean

  @Prop()
  capacityThreshold: number | undefined

  @Prop()
  tabsConfig: SessionPickerTabsConfig

  t: LanguageStrings['selectSession']

  mounted() {
    if (this.annotatedAvailableSessions.length === 1) {
      this.$emit('input', this.annotatedAvailableSessions[0])
    }
  }

  get annotatedAvailableSessions(): AnnotatedSession[] {
    return this.nonConflictingSessions.map((session) => annotateSession(session, this.event, this.quantities))
  }

  get sessionAnnotations(): MessageAction[] {
    return uniqueAnnotations(this.annotatedAvailableSessions)
  }

  get taxesAndFeesMessage(): string | null {
    if (this.showPrices) {
      return showAdvertisedFees() ? this.t.taxIncludedKeyLabel : this.t.taxExcludedKeyLabel
    }
    return null
  }

  get formattedConflictingSessions(): string {
    return formatSessionList(this.conflictingSessions.filter((session) => !session.sold_out))
  }

  private get nonConflictingSessions(): Session[] {
    if (this.isAllDayEvent) {
      return this.sessions
    } else {
      const conflicting = new Set(this.conflictingSessions.map((session) => session.id))
      return this.sessions.filter((session) => !conflicting.has(session.id))
    }
  }

  get showSoldOutTimes(): boolean {
    return !hideSoldOutSessions(this.event, environment)
  }

  private get conflictingSessions(): Session[] {
    // TODO Should this should return [] on all day events? Test handling of all day events that conflict other sessions in the cart.
    if (categoriesThatMayConflict.has(this.event.category)) {
      return []
    } else {
      return conflictingSessions(this.event.id, this.event.meta, this.sessions, this.$store.getters['Cart/cartItems'])
    }
  }

  get currencySymbol() {
    return currencySymbol()
  }

  get title() {
    return this.$t('selectAvailableSession.title')
  }
}
