import type { RawThemeConfig } from '@/themeConfig/types'

export const black = 'black'
export const white = 'white'
export const darkGray = '#222222'
export const mediumGray = '#555555'
export const lightGray = '#DDE0E5'
export const veryLightGray = '#f7f7f7'

export function ticketureBrandThemeConfig(): RawThemeConfig {
  return {
    tier: 1,
    primaryColor: '#4694E6',
    primaryContrastColor: 'white',
    secondaryColor: '#4694E6',
    headerBackground: '#003567',
    memberLinksColor: 'white',
    memberLinksColorHover: '#4694E6',
    cartHeaderBackgroundColor: '#003567',
    footerBackgroundColor: '#003567',
    footerTextColor: 'white',
    pageBackgroundColor: '#F7F7F7',
    headerDropShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.4)',
    logoHeightDesktop: '32px',
    headerHeightDesktop: '105px',
  }
}

export function unbrandedFallbackThemeConfig(): Partial<RawThemeConfig> {
  const primaryColor = 'var(--primary-color)'
  const pageBackgroundColor = 'var(--page-background-color)'
  const primaryColorDarker = 'var(--primary-color-darker)'

  return {
    primaryColor: darkGray,
    secondaryColor: darkGray,
    messageColor: darkGray,
    labelColor: [darkGray, 'white'],
    headerBackground: ['white', 'transparent'],
    memberLinksColor: [darkGray, 'white'],
    memberLinksColorHover: [primaryColorDarker, transparentWhite(0.5)],
    logoHeight: '32px',
    footerBackgroundColor: ['white', transparentWhite(0.09)],
    footerTextColor: [mediumGray, transparentWhite(0.6)],
    bodyColor: [mediumGray, transparentWhite(0.6)],
    headingColor: ['black', transparentWhite(0.87)],
    pageBackgroundColor: ['white', 'black'],
    headerTopBorder: 'none',
    headerBottomBorder: 'none',
    headerDropShadow: 'none',
    headerHeight: '105px',
    secondaryButtonStyle: 'outline',
    cartBorder: ['1px solid var(--light-gray)', `1px solid ${transparentWhite(0.1)}`],
    infoColor: '#3b73d8',
    successColor: '#006400',
    warningColor: '#ffa500',
    errorColor: '#eb1c26',
    promoColor: '#9877b0',
    primaryFont: 'proxima-nova, Arial, sans-serif',
    secondaryFont: 'proxima-nova, Arial, sans-serif',
    externalFontsStylesheets: ['https://use.typekit.net/tsu4pxd.css'],
    selectArrowColor: ['var(--primary-color)', 'white'],
    cart: {
      headerBackgroundColor: [darkGray, transparentWhite(0.13)],
      headerTextColor: transparentWhite(0.87),
      headerBorder: 'none',
      backgroundColor: ['white', pageBackgroundColor],
      priceBackgroundColor: ['#f7f7f7', transparentWhite(0.05)],
      dividerColor: [lightGray, transparentWhite(0.13)],
      removeButtonBackgroundColor: [lightGray, transparentWhite(0.05)],
    },
    dialog: {
      backgroundColor: ['#F7F9FA', 'var(--page-background-color-lighter)'],
      headerTextColor: ['black', 'white'],
      headerBackground: ['white', transparentWhite(0.05)],
      headerIconColor: ['#555555', transparentWhite(0.25)],
      headerBorder: ['1px solid #DDE0E6', 'none'],
    },
    mobileFooter: {
      backgroundColor: ['#ffffff', pageBackgroundColor],
      textColor: ['#545557', transparentWhite(0.6)],
      cartButtonBackgroundColor: ['#ffffff', pageBackgroundColor],
      cartButtonHoverBackgroundColor: ['#ffffff', pageBackgroundColor],
      cartButtonBorder: ['2px solid #BFC1CA', '2px solid rgba(255, 255, 255, 0.2)'],
      cartButtonIconColor: ['#747981', '#ffffff'],
      cartBadgeBackgroundColor: '#ff0000',
      cartBadgeTextColor: '#ffffff',
      priceTextColor: ['#545557', transparentWhite(0.87)],
    },
    backButton: {
      iconColor: '#282B37',
      backgroundColor: 'white',
      shadow: '0px 0px 5px rgba(0, 0, 0, 0.4)',
    },
    calendar: {
      backgroundColor: ['rgba(255, 255, 255, 0.1)', 'rgba(34, 36, 51, 0.1)'],
      buttonColor: ['#ffffff', transparentWhite(0.05)],
      buttonIconColor: ['#282B37', transparentWhite(0.87)],
      dayTextColor: ['#282B37', transparentWhite(0.6)],
      selectedDayTextColor: 'var(--primary-contrast-color)',
      todayBackgroundColor: ['#EFF0F5', 'rgba(255,255,255,0.2)'],
      disabledDayTextColor: ['#BFC1CA', transparentWhite(0.3)],
      divider: ['1px dashed #cccccc', `1px dashed ${transparentWhite(0.1)}`],
      monthTextColor: ['#282B37', transparentWhite(0.87)],
      weekdayTextColor: ['#525A6C', transparentWhite(0.87)],
      legendTextColor: ['#525A6C', transparentWhite(0.6)],
    },
    steppers3: {
      iconColor: [mediumGray, 'white'],
      border: [`2px solid ${lightGray}`, `2px solid ${transparentWhite(0.18)}`],
      buttonBackgroundColor: ['white', transparentWhite(0.05)],
      buttonBackgroundHoverColor: ['white', transparentWhite(0.15)],
      borderHoverColor: ['#bfc1ca', 'rgba(255, 255, 255, 0.5)'],
      disabledIconColor: ['var(--light-gray)', transparentWhite(0.2)],
      count: {
        backgroundColor: [lightGray, 'transparent'],
        border: [`2px solid ${lightGray}`, `2px solid ${transparentWhite(0.18)}`],
      },
    },
    radioButton: {
      borderColor: ['var(--medium-gray)', transparentWhite(0.18)],
      backgroundColor: ['white', transparentWhite(0.05)],
      borderWidth: '2px',
      checked: {
        backgroundColor: ['white', 'transparent'],
        borderColor: primaryColor,
      },
    },
    editOrder: {
      sessionBorderColor: ['#dde0e5', transparentWhite(0.09)],
      ticketBackgroundColor: ['#eff0f5', transparentWhite(0.05)],
    },
    divider: [`${lightGray} 1px solid`, `${transparentWhite(0.1)} 1px solid`],
  }
}

function transparentWhite(decimal: number): string {
  return `rgba(255, 255, 255, ${decimal})`
}
