import { toKebabCase } from '@/helpers/StringHelpers'
import BuyMembershipStory from '@/stories/BuyMembershipStory.vue'
import CartWidgetStory from '@/stories/CartWidgetStory.vue'
import DeleteCartOrCheckoutModalStory from '@/stories/DeleteCartOrCheckoutModalStory.vue'
import ElementsStory from '@/stories/ElementsStory.vue'
import EventDetailErrorMessagesStory from '@/stories/EventDetailErrorMessagesStory.vue'
import EventListingItemStory from '@/stories/EventListingItemStory.vue'
import MembersMenuStory from '@/stories/MembersMenuStory.vue'
import ModalStory from '@/stories/ModalStory.vue'
import NamedMembersStory from '@/stories/NamedMembersStory.vue'
import ReserveDateFirstStory from '@/stories/ReserveDateFirstStory.vue'
import SelectGroupQuantitiesStory from '@/stories/SelectGroupQuantitiesStory.vue'
import SelectQuantitiesStory from '@/stories/SelectQuantitiesStory.vue'
import SelectSessionStory from '@/stories/SelectSessionStory.vue'
import StepperStory from '@/stories/StepperStory.vue'
import TermsCheckboxStory from '@/stories/TermsCheckboxStory.vue'
import CartExpiryModalStory from './CartExpiryModalStory.vue'
import DatePickerStory from './DatePickerModalStory.vue'
import LoginModalStory from './LoginModalStory.vue'
import LoginOrGuestModalStory from './LoginOrGuestModalStory.vue'
import MembershipModalStory from './MembershipModalStory.vue'
import TermsModalStory from './TermsModalStory.vue'

const stories = [
  {
    name: 'Session selection',
    component: SelectSessionStory,
  },
  {
    name: 'Date-first',
    component: ReserveDateFirstStory,
  },
  {
    name: 'BuyMembership story',
    component: BuyMembershipStory,
  },
  {
    name: 'Named members',
    component: NamedMembersStory,
  },
  {
    name: 'Select quantities',
    component: SelectQuantitiesStory,
  },
  {
    name: 'SelectGroupQuantities story',
    component: SelectGroupQuantitiesStory,
  },
  {
    name: 'Elements story',
    component: ElementsStory,
  },
  {
    name: 'CartWidget story',
    component: CartWidgetStory,
  },
  {
    name: 'MembersMenu story',
    component: MembersMenuStory,
  },
  {
    name: 'CartExpiryModal story',
    component: CartExpiryModalStory,
  },
  {
    name: 'TermsCheckbox story',
    component: TermsCheckboxStory,
  },
  {
    name: 'TermsModal story',
    component: TermsModalStory,
  },
  {
    name: 'LoginModal story',
    component: LoginModalStory,
  },
  {
    name: 'MembershipModal story',
    component: MembershipModalStory,
  },
  {
    name: 'LoginOrGuestModal story',
    component: LoginOrGuestModalStory,
  },
  {
    name: 'DeleteCartOrCheckoutModal story',
    component: DeleteCartOrCheckoutModalStory,
  },
  {
    name: 'Stepper story',
    component: StepperStory,
  },
  {
    name: 'Modal story',
    component: ModalStory,
  },
  {
    name: 'EventListingItem story',
    component: EventListingItemStory,
  },
  {
    name: 'Date Picker story',
    component: DatePickerStory,
  },
  {
    name: 'EventDetailErrorMessages Story',
    component: EventDetailErrorMessagesStory,
  },
]

// sort Stories by name
stories.sort((a, b) => {
  return a.name.localeCompare(b.name)
})

export default stories.map((story) => {
  return {
    path: toKebabCase(story.name),
    ...story,
  }
})
