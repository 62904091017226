import { API } from '@/api/API'
import { auth0Client } from '@/helpers/Auth0Client'
import { authenticationMethod, environment } from '@/helpers/Environment'
import store from '@/store/store'

import type VueRouter from 'vue-router'

export async function logout(router?: VueRouter, nextPath?: string) {
  const externalLogoutUrl = environment.web.external_urls?.logout
  if (!externalLogoutUrl) {
    await API.post('logout')
  }
  await store.dispatch('clearAll')
  if (authenticationMethod === 'auth0_ecomm') {
    nextPath ??= window.location.pathname
    // @see https://auth0.com/docs/quickstart/spa/vuejs/01-login#create-an-authentication-wrapper
    auth0Client.then((client) => {
      if (client) {
        client.logout({ returnTo: window.location.origin + nextPath })
      }
    })
  } else if (externalLogoutUrl) {
    window.location.href = externalLogoutUrl
  } else if (router && nextPath) {
    router.push(nextPath)
  }
}

export function ensureLoggedOut() {
  return isLoggedIn() ? logout() : Promise.resolve()
}

export function isLoggedIn(): boolean {
  return Boolean(store.getters['Member/user'])
}
