
import { Component, Vue } from 'vue-property-decorator'
import TixStepper from '@/components/forms/TixStepper.vue'
import FormInput2 from '@/components/forms/FormInput2.vue'
import AutocompleteInput from '@/components/forms/AutocompleteInput.vue'
import TixTabs from '@/components/elements/TixTabs.vue'
import type { AddressSearchResult } from '@/helpers/AddressHelpers'
import type { TixTab } from '@/types/TixTab'

@Component({ name: 'ElementsStory', components: { FormInput2, TixStepper, AutocompleteInput, TixTabs } })
export default class extends Vue {
  selectedTab = 'selected'

  mounted() {
    const autocomplete = this.$refs.autocomplete as Vue
    const address = autocomplete.$el.getElementsByClassName('autocomplete-list-item')[1]
    address.setAttribute('class', address.getAttribute('class') + ' hover')
  }

  get autocompleteResults(): Partial<AddressSearchResult>[] {
    return [
      { Text: '123 Main Street, Auckland, NZ', Description: ' - 70 Addresses' },
      { Text: '127 Test Road, Christchurch, NZ', Description: '' },
      { Text: '127 Random Avenue, Wellington, NZ', Description: '' },
      { Text: '128 Random Avenue, Wellington, NZ', Description: '' },
      { Text: '129 Random Avenue, Wellington, NZ', Description: '' },
    ]
  }

  get tixTabs(): TixTab[] {
    const longTitle = 'This is a really long title that probably will not fit in the space available'
    return [
      { id: 'with-subtitle', title: 'Not selected', subtitle: 'with subtitle' },
      { id: 'selected', title: 'Selected' },
      { id: 'long-title', title: longTitle },
      {
        id: 'disabled',
        title: 'Disabled',
        subtitle: 'with custom classes and style',
        disabled: true,
        classNames: 'alert',
      },
    ]
  }
}
