import type { LinkedTG } from '@/api/types/processedEntities'
import { sum } from '@/helpers/MiscellaneousHelpers'
import { exactlyOneTicket } from '@/helpers/Reserve'
import { lowerTrim } from '@/helpers/StringHelpers'
import type { TTQuantities } from '@/seats/helpers'
import type { CartItem, CartItemType, TimedItem } from '@/store/CartItem'
import type { Session } from '@/types/Sessions'

export function quantitiesPayload(quantities: TicketTypeQuantities) {
  // Filter out ticket type quantities where the quantity is 0
  // Requesting sold out ticket types, even if the ticket quantity requested is 0, will cause the API to return 'sold_out' for sessions
  return {
    ticket_types_required: Object.entries(quantities)
      .filter(([typeId, value]) => value.quantity > 0)
      .map(([typeId, value]) => ({
        ticket_type_id: typeId,
        quantity: value.quantity,
      })),
  }
}

export function filterQuantitiesByGroups(quantities: TicketTypeQuantities, groups: LinkedTG[]): TTQuantities {
  const result = {}
  for (const group of groups) {
    for (const type of group.types) {
      result[type.id] = quantities[type.id]?.quantity
    }
  }
  return result
}

export function quantitiesAreEqual(aQuantities: TicketTypeQuantities, bQuantities: TicketTypeQuantities): boolean {
  const aEntries = Object.entries(aQuantities)
  const bEntries = Object.entries(bQuantities)
  return (
    aEntries.length === bEntries.length &&
    aEntries.every(([key, a]) => {
      const b = bQuantities[key]
      return a.quantity === b.quantity && a.price === b.price
    })
  )
}

export function sumQuantities(quantities: TicketTypeQuantities): number {
  const values = Object.values(quantities).map((value) => value.quantity)
  return sum(values)
}

export function createQuantities(
  groups: LinkedTG[],
  session: Session | null,
  anchor: TimedItem | null,
  hasSteppers: boolean,
): TicketTypeQuantities {
  // Attempt to match quantities for upsells on the same day.
  if (session && anchor && session.startTime.isSame(anchor.startTime, 'day')) {
    const result = matchingQuantities(groups, session, anchor, hasSteppers)
    if (result) {
      return result
    }
  }

  return createQuantitiesDictionary(groups, () => {
    // Default quantity to 1 if we aren't showing quantity selection.
    return !hasSteppers || exactlyOneTicket(groups) ? 1 : 0
  })
}

function matchingQuantities(
  groups: LinkedTG[],
  session: Session,
  anchor: TimedItem,
  hasSteppers: boolean,
): TicketTypeQuantities | void {
  const result = quantitiesToMatchAnchor(anchor, groups, hasSteppers)
  const totalMatching = sumQuantities(result)
  // Only match quantities if there is sufficient capacity.
  if (totalMatching <= session.availableCapacity) {
    return result
  }
}

export function quantitiesToMatchAnchor(
  anchor: CartItem,
  groups: LinkedTG[],
  hasSteppers: boolean,
): TicketTypeQuantities {
  // Default quantity to 1 if we aren't showing quantity selection.
  if (!hasSteppers || exactlyOneTicket(groups)) {
    return createQuantitiesDictionary(groups, () => 1)
  }

  const map = mapAnchorTypesByNameAndHiddenType(anchor)

  return createQuantitiesDictionary(groups, (type, group) => {
    if (group.handler === 'tickets' || group.handler === 'seated') {
      const name = lowerTrim(type.name)
      const types: CartItemType[] = map[name]?.[group.hidden_type] ?? []

      if (types.length === 1) {
        return types[0].ticketCount
      } else {
        const type = types.find((type) => lowerTrim(type.group.name) === lowerTrim(group.name))
        if (type) {
          return type.ticketCount
        }
      }
    }

    return 0
  })
}

function mapAnchorTypesByNameAndHiddenType(anchor: CartItem): Dict<Dict<CartItemType[]>> {
  const result: Dict<Dict<CartItemType[]>> = {}

  for (const type of anchor.types) {
    const name = lowerTrim(type.name)
    const hiddenType = type.group.hidden_type
    result[name] ??= {}
    result[name][hiddenType] ??= []
    result[name][hiddenType].push(type)
  }

  return result
}

function createQuantitiesDictionary(
  groups: LinkedTG[],
  callback: (type: TicketType, group: LinkedTG) => number,
): TicketTypeQuantities {
  const result = {}

  for (const group of groups) {
    for (const type of group.types) {
      const quantity = callback(type, group)
      result[type.id] = { quantity }
    }
  }

  return result
}
