
import FormInput2 from '@/components/forms/FormInput2.vue'
import NamedMembers from '@/components/membership/NamedMembers.vue'
import { randomIdentifier } from '@/helpers/StringHelpers'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'NamedMembersStory',
  components: { NamedMembers, FormInput2 },
})
export default class extends Vue {
  identifier: string = randomIdentifier()
  emailRequired: MembershipAdmitDetailsConfig = 'optional'

  value = []
  name = 'Additional member'

  // Form inputs force controls to string values.
  required = '2'
  optional = '3'

  get options() {
    return ['yes', 'no', 'optional'].map((value) => ({ value, label: value }))
  }

  @Watch('required')
  @Watch('optional')
  @Watch('emailRequired')
  generateKey() {
    // Update the :key="" attribute to force a re-render when inputs change that do not otherwise trigger a re-render.
    this.identifier = randomIdentifier()
  }
}
