
import TicketTypeStepper from '@/components/events/TicketTypeStepper.vue'
import { hasSeated } from '@/helpers/TicketGroupHelpers'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { LinkedTG } from '@/api/types/processedEntities'
import type { LinkedSessionTG } from '@/helpers/SessionHelpers'

@Component({
  name: 'SelectGroupQuantities',
  components: {
    TicketTypeStepper,
  },
})
export default class extends Vue {
  @Prop({ required: true })
  ticketGroup: LinkedTG | LinkedSessionTG

  @Prop({ required: true })
  quantities: TicketTypeQuantities

  @Prop({ required: true })
  remainingCapacity: TicketTypeQuantities

  @Prop({ default: false })
  giftOfMembership: boolean

  @Prop({ default: false })
  showHeaders: boolean

  @Prop({ default: false })
  showAdvertisedFees: boolean

  @Prop({ default: false })
  exactlyOneTicket: boolean

  get showName(): boolean {
    const tg = this.ticketGroup
    if (this.isSeated) {
      return true
    } else {
      // Omit the title if the description is empty, or it matches the name. This supports three scenarios;
      //   1. Show TG name and description (summary), when description is not empty
      //   2. Show TG name only, when description matches name
      //   3. Show neither, when description is empty
      //  There is some similarly strange behavior in <TicketTypeStepper> for subtitles.
      return tg.description.length > 0 && tg.description !== tg.name
    }
  }

  get isSeated(): boolean {
    return hasSeated([this.ticketGroup])
  }

  get className(): string | null {
    return this.isSeated ? `ticket-group-${this.ticketGroup.color}-color` : null
  }

  get groupMinQuantity(): number {
    const group = this.ticketGroup
    // Only apply the minimum guard when the group has a single ticket type
    if (group.types.length === 1 && group.min_tickets_per_order) {
      return Math.max(Number(group.min_tickets_per_order), 1)
    } else {
      return 1
    }
  }

  get isSoldOut(): boolean {
    // Only SessionTGs and LinkedSessionTGs have the `sold_out` property. Other TG types do not.
    if ('sold_out' in this.ticketGroup) return this.ticketGroup.sold_out
    else return false
  }
}
