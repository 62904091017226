import { dictToMap, mapEntries } from '@/helpers/DictHelpers'

// Extracts the names of Type's properties that may be used as object keys.
type KeyableProperties<Type> = PickKeyable<Type>[keyof Type]
type PickKeyable<Type> = {
  [Key in keyof Type]: Type[Key] extends Primitive | null ? Key : never
}

export function groupBy<T extends {}, P extends KeyableProperties<T>>(property: P, items: T[]): Dict<T[]> {
  const result: Dict<T[]> = {}

  items.forEach((item) => {
    const key = item[property]
    result[key] = result[key] || []
    result[key].push(item)
  })

  return result
}

export function indexBy<T extends {}, P extends KeyableProperties<T>>(property: P, items: T[]): Dict<T> {
  return mapEntries(groupBy(property, items), (items) => {
    if (items.length !== 1) {
      throw new Error(`indexBy(${property}) encountered duplicate keys`)
    }
    return items[0]
  })
}

// TODO Rename to indexById().
export function indexItemsById<T extends { id: string }>(items: T[]): Dict<T> {
  return indexBy('id' as KeyableProperties<T>, items)
}

export function groupAsMap<P, C>(items: C[], property: KeyableProperties<C>, keys: Dict<P>): Map<P, C[]> {
  return dictToMap(groupBy(property, items), keys)
}

/**
 * Returns the unique values of the specified property of each item in items.
 */
export function uniquePropertyValues<T, K extends keyof T>(name: K, items: T[]): Array<T[K]> {
  return Array.from(new Set(items.map((item) => item[name])))
}

export function itemIDs(items: { id: string }[]) {
  return items.map((item) => item.id)
}
