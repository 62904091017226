
import ReserveDateFirst from '@/components/events/ReserveDateFirst.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'ReserveDateFirstStory',
  components: { ReserveDateFirst },
})
export default class extends Vue {
  event = {
    id: '123',
    ticketGroups: [
      {
        id: '456',
        types: [{ name: 'Admission' }],
      },
    ],
  }
}
