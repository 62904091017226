
import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @deprecated Use <FormInput type="select" :options="" />, which supports a RadioGroupOption[] for the options prop.
 */
@Component({ name: 'Select' })
export default class extends Vue {
  @Prop({ required: false, default: null })
  value: string

  @Prop({ required: false, default: 'Select an option' })
  defaultOptionLabel: string

  // TODO Should be a RadioGroupOption[]
  @Prop({ required: true, default: null })
  options: any[]

  get input() {
    return this.value
  }

  set input(newValue) {
    this.$emit('input', newValue)
  }

  mounted() {
    if (!this.value) {
      this.$el['selectedIndex'] = -1
    }
  }

  onBlur(e) {
    this.$emit('blur', e)
  }

  onChange(...args) {
    this.$emit('change', ...args)
  }
}
