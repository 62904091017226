import { TixTime } from '@/TixTime/TixTime'
import type { Session } from '@/types/Sessions'

export function mockSession(hour: number, options: Partial<Session> = {}): Partial<Session> {
  return {
    availableCapacity: 10000,
    id: String(hour),
    startTime: new TixTime(`2030-12-31T${String(hour).padStart(2, '0')}:00Z`, 'UTC'),
    endTime: new TixTime(`2030-12-31T${String(hour).padStart(2, '0')}:30Z`, 'UTC'),
    price: 10,
    minPrice: 5,
    maxPrice: 15,
    ...options,
  }
}
