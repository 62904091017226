
import type { WalletType } from '@/checkout/helpers/wallets'
import CartWidget from '@/components/cart/CartWidget.vue'
import CheckoutMobileFooter from '@/components/cart/CheckoutMobileFooter.vue'
import MobileCartWidget from '@/components/cart/MobileCartWidget.vue'
import MobileFooterPortal from '@/components/cart/MobileFooterPortal.vue'
import { Component, Vue } from 'vue-property-decorator'
import { openCartModal } from '@/modals/cartModal'
import type { PaymentMethodName } from '@/checkout/stripe/helpers'

@Component({
  name: 'CartWidgetStory',
  components: {
    CartWidget,
    MobileCartWidget,
    MobileFooterPortal,
    CheckoutMobileFooter,
  },
})
export default class extends Vue {
  submitting = false
  selectedPaymentType: PaymentMethodName | null = null
  supportedWallet: WalletType | null = null
  isModifiable: boolean = true
  processing: boolean = false
  blocked: boolean = false

  openMobileCart() {
    openCartModal()
  }
}
