
import { Component, Vue } from 'vue-property-decorator'
import { openMembershipModal } from '@/modals/membershipModal'

@Component({})
export default class MembershipModalStory extends Vue {
  mounted() {
    openMembershipModal()
  }
}
