
import MembersMenu from '@/components/membership/MembersMenu.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'MembersMenuStory',
  components: { MembersMenu },
})
export default class extends Vue {
  get user() {
    return {
      first_name: 'Test User',
      email: 'test@test.com',
    }
  }
}
