
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({ name: 'AvailabilityStatus' })
export default class extends Vue {
  @Prop({ required: true })
  soldOut: boolean

  @Prop()
  availableCapacity: number

  @Prop()
  capacityThreshold: number

  get classes() {
    const result = ['availability', 'row']
    if (this.lowAvailability) {
      result.push('danger')
    }

    return result
  }

  get formattedAvailability(): string {
    if (!this.availableCapacity) {
      return 'Available'
    }

    // TODO get 'Available' form the language file
    const label = this.lowAvailability ? `${this.availableCapacity} available` : 'Available'
    return label
  }

  get lowAvailability(): boolean {
    return this.availableCapacity < (this.capacityThreshold ?? 0)
  }
}
