import { tenantI18nStrings } from '@/helpers/Config'
import { defaultLanguage } from '@/helpers/Environment'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

// Vue must be configured to use the Vue18n component first.
// @see https://stackoverflow.com/a/64402138
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: defaultLanguage,
  messages: tenantI18nStrings(),
})
