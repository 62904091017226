import { languageItem } from '@/helpers/LanguageHelpers'
import { openModal } from '@/modals/helpers/api'
import MembershipModal from '@/modals/MembershipModal.vue'

export function openMembershipModal() {
  const t = languageItem('membershipModal')
  openModal({
    name: 'membership-modal',
    title: t.title,
    component: MembershipModal,
  })
}
