
import EventListingItem from '@/components/events/EventListingItem.vue'
import EventListingWithFilters from '@/components/events/EventListingWithFilters.vue'
import MembersMenu from '@/components/membership/MembersMenu.vue'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'EventListingItemStory',
  components: { EventListingWithFilters, EventListingItem, MembersMenu },
})
export default class extends Vue {
  event(subtitle: boolean, summary: boolean, image: boolean) {
    return {
      _rank: 30000,
      capacity: 2147483647,
      category: 'Public',
      dedicated_order: false,
      description: 'Event description',
      event_type: 'timed_entry',
      hidden_type: 'public_link_only',
      id: 'f7bb3f06-ef1b-1b15-624d-a5cd0ca109bb',
      name: "E2E Admissions (Don't change)",
      oversell_capacity: 10,
      portal_id: 'd3e8e658-a074-4956-a941-270039b75ac4',
      release_sessions_until: '2030-10-16T06:59:59Z',
      scan_code_type: 'random',
      seller_id: '45c78bb9-59af-e62e-07a2-0652425c00cf',
      stage_size: -1,
      subtitle: subtitle ? 'Subtitle' : '',
      summary: summary
        ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec vitae hendrerit nulla. Sed aliquet, metus eget interdum cursus, urna leo.'
        : '',
      ticket_template_id: null,
      venue_id: 'a4e053ee-2982-4ad5-3464-8ab5204e592a',
      config: {},
      meta: {
        image_profile: image
          ? 'https://api.d1tix.com/v1/assets/test/c7957918-c292-50ff-99df-821fed9f36c1'
          : undefined,
      },
      venue: {
        name: 'Test Venue',
      },
    }
  }
}
