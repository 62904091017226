
import { Component, Vue } from 'vue-property-decorator'
import { openDeleteCartOrCheckoutDialog } from '@/components/membership/DeleteCartOrCheckoutDialog.vue'

@Component({})
export default class DeleteCartOrCheckoutModalStory extends Vue {
  mounted() {
    openDeleteCartOrCheckoutDialog({
      title: 'Buy Membership',
      description: 'You need to take action on your current cart before you can buy another membership.',
    })
  }
}
