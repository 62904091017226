<script>
  import CountrySelect from './CountrySelect'
  import Select from './Select'
  import StateSelect from './StateSelect'

  /**
   * @deprecated Use <FormInput2>.
   */
  export default {
    name: 'FormInput',

    components: {
      CountrySelect,
      StateSelect,
      Select,
    },

    props: [
      'autocomplete',
      'classInner',
      'disabled',
      'error',
      'id',
      'label',
      'minlength',
      'maxlength',
      'min',
      'max',
      'required',
      'type',
      'value',
      'autofocus',
      'placeholder',
      'defaultOptionLabel',
      'selectOptions',
    ],

    computed: {
      /**
       * Avoid mutating prop
       *
       * @returns {string}
       */
      input: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        },
      },

      /**
       * @deprecated Templates and themes should set & use the class on the root div instead. E.g.
       *   .form-input.valid
       *   .form-input.invalid
       *   .form-input.invalid > input
       */
      innerClasses() {
        // Let Vue merge the `classInner` classes passed in via props and the `invalid` class.
        return [this.classInner, { invalid: Boolean(this.error) }]
      },
    },

    methods: {
      onBlur(...args) {
        this.$emit('blur', ...args)
      },

      onFocus(...args) {
        this.$emit('focus', ...args)
      },

      onKeyPress(...args) {
        this.$emit('keypress', ...args)
      },

      onChange(...args) {
        this.$emit('change', ...args)
      },

      xautocomplete(autocomplete) {
        if (autocomplete === 'family-name') {
          return 'surname'
        }

        return autocomplete
      },
    },
  }
</script>

<template>
  <label
    :class="{
      [type]: true,
      valid: !error,
      invalid: error,
      selected: input && type === 'checkbox',
    }"
    class="form-input"
    :for="id"
  >
    <span v-if="type !== 'checkbox'" class="label">
      {{ label }} <small v-if="!required" class="optional">({{ t.optional }})</small>
    </span>

    <CountrySelect
      v-if="type === 'country'"
      v-model="input"
      @blur="onBlur"
      ref="input"
      :class="innerClasses"
      :id="id"
      :name="id"
      :required="required"
    ></CountrySelect>

    <StateSelect
      v-else-if="type === 'state'"
      v-model="input"
      @blur="onBlur"
      ref="input"
      :class="innerClasses"
      :disabled="disabled"
      :id="id"
      :name="id"
      :required="required"
      :selected="input"
    ></StateSelect>

    <Select
      v-else-if="type === 'select'"
      v-model="input"
      @blur="onBlur"
      @change="onChange"
      ref="input"
      :class="innerClasses"
      :disabled="disabled"
      :id="id"
      :name="id"
      :required="required"
      :selected="input"
      :defaultOptionLabel="defaultOptionLabel"
      :options="selectOptions"
    ></Select>

    <input
      v-else
      v-model="input"
      @blur="onBlur"
      @focus="onFocus"
      @keypress="onKeyPress"
      @change="onChange"
      ref="input"
      :autocomplete="autocomplete"
      :class="innerClasses"
      :id="id"
      :name="id"
      :minlength="minlength"
      :maxlength="maxlength"
      :min="min"
      :max="max"
      :required="required"
      :type="type"
      :x-autocompletetype="xautocomplete(autocomplete)"
      :placeholder="placeholder"
      v-autofocus="autofocus"
    />

    <span v-if="type === 'checkbox'" class="label">
      <slot name="label">{{ label }}</slot>
    </span>

    <slot />

    <span v-if="error" class="message invalid">{{ error }}</span>
  </label>
</template>

<!-- This is not scoped, so that these styles can be re-used on faux FormInput elements like Stripe fields. -->
<style lang="scss"></style>
