import type { LanguageStrings } from '@/language/types'

const english: LanguageStrings = {
  date: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    previousMonth: 'Previous Month',
    nextMonth: 'Next Month',
    months: 'January, February, March, April, May, June, July, August, September, October, November, December',
    weekdays: 'Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday',
    weekdaysShort: 'S, M, T, W, T, F, S',
  },

  datePicker: {
    soldOutDate: 'Sold out date',
  },

  dateFormat: {
    medium: 'MMMM DD YYYY',
    long: 'dddd, MMMM Do YYYY',
  },

  dateTimeFormat: {
    long: 'dddd, MMMM Do h:mm A',
  },

  linkTitle: 'Navigate to "{name}" event',

  navigateBack: 'Back',

  formInput: {
    optional: 'optional',
  },

  giftAidCheckbox: {
    heading: 'Gift Aid your order',
    description: 'Support Ticketure by choosing to Gift Aid your order at <strong>no extra cost to you.</strong>',
  },

  homeRoute: {
    title: 'Welcome',
    loginTitle: 'Already a member',
    signupTitle: 'Become a member',
    guestTitle: 'Continue as a guest',
    resendTicketsTitle: 'Already have tickets?',
    signupButton: 'Sign up for membership',
    guestButton: 'Buy tickets',
    resendTicketsLink: 'Resend confirmation link',
  },

  memberLookupNotFoundWeb: 'Member information not found. Please check your member number and zip code.',

  loginForm: {
    invalidCredentials: {
      scan_code: 'Member details not found. Please check your Member ID and ZIP Code.',
      scan_code_last_name: 'Member details not found. Please check your member ID and last name.',
      scan_code_password: 'Member details not found. Please check your email address and member ID.',
    },
    loginButton: 'Log In',
    notAMember: "Don't have a membership?",
    becomeAMember: 'Become a Member',
    forgotPassword: 'Forgot Your Password?',
    forgotMembership: 'Forgot Your Membership ID?',
    lastNameLabel: 'Primary Member Last Name',
    title: 'Member Login',
    memberID: 'Member ID',
  },

  loginOrGuestDialog: {
    title: 'Are you a member?',
  },

  forgotMembership: {
    title: 'Forgot your membership ID?',
  },

  completedOrdersRoute: {
    title: '<span>Done!</span> We look forward to your visit',
    ticketsOrderNumber: 'Your Experience Order Number is {number}',
    membershipOrderNumber: 'Your Membership Order Number is {number}',
    giftOrderNumber: 'Your Gift Order Number is {number}',
    printOrder: 'View and print your tickets',
  },

  pendingOrdersRoute: {
    title: '<span>Done!</span> Your order has been received',
    referenceNumber: '<p>Reference Number</p> {number}',
    message:
      '<p>Once your initial debit payment has been successfully cleared with your bank, you will receive an email confirming the completion of your order and the activation of your membership.</p>',
  },

  eventListingRoute: {
    title: 'Choose your events',
    upsellsTitle: 'What other experiences would you like to add?',
  },

  eventListingItem: {
    buttonText: 'Select',
  },

  dateFilters: {
    all: 'All Dates',
  },

  categoryFilters: {
    all: 'All',
  },

  reserve: {
    unavailable: 'Sorry, tickets are not available for this event.',
    unavailableOnDate: 'Sorry, tickets are not available for this event on the date of your visit.',
    sessionUnavailable: 'Sorry, tickets for this event are not available at the selected time.',
    sessionNotFound: 'Sorry, the selected time is not found.',
    ticketTypesNotFound:
      'Sorry, the selected ticket type is not available. | Sorry, the selected ticket types are not available.',
    title: 'Select Tickets',
    buttonLabel: {
      default: 'Add to Order',
      tickets: 'Add 1 Ticket to Order | Add {count} Tickets to Order',
      passes: 'Add 1 Pass to Order | Add {count} Passes to Order',
      donation: 'Add Donation to Order',
      giftcards: 'Add Gift to Order',
      member: 'Add Membership to Order',
    },
    ticketQuantity: '1 ticket | {count} tickets',
    skipAddOn: 'Skip Add-on',
    addOnHelpText: 'You can upgrade your experience by selecting this optional add-on.',
    fewTicketsWarning: 'Hurry, less than 10 tickets available.',
    selectDateLabel: 'Select a date',
    selectDateDescription: 'Which day would you like to visit?',
  },

  reserveDateFirst: {
    pickFlexibleTickets: 'View options that allow a flexible date',
  },

  reserveQuantityFirst: {
    findAvailableTickets: 'Find Available Tickets',
  },

  selectQuantities: {
    title: '',
  },

  stepperGroupTitles: {
    members: 'Select member {type}',
    guests: 'Select non-member {type}',
    default: 'Select {type}',
    giftOfMembership: 'Select memberships',
  },

  stepperGroupDescriptions: {
    members: 'Who is coming to this session?',
    guests: 'Who is coming to this session?',
    default: 'Who is coming to this session?',
    giftOfMembership: 'What level of membership would you like to gift?',
    passes: 'How many passes would you like?',
  },

  admitDetailsFields: {
    title: 'Enter your details',
    label: '{ttName} {number}',
  },

  citypassCoupons: {
    enableQuestion: 'Will you be redeeming CityPASS vouchers for this visit?',
    instructions: 'Please enter your CityPASS voucher numbers',
  },

  // TODO Move to .selectSession.conflictTimes.
  conflictTimes: 'These times conflict with your other tickets:',
  soldOutTimes: 'These times are sold out or do not have enough tickets available:',

  selectSession: {
    description: 'What time would you like to attend?',
    taxIncludedKeyLabel: 'Prices include taxes & fees',
    taxExcludedKeyLabel: '',
  },

  selectAvailableSession: {
    title: 'Select a time',
  },

  tixPayment: {
    title: 'Payment Details',
  },

  accountLinks: {
    loginLinkShortLabel: 'Login',
    registerLinkShortLabel: 'Membership',
    loginLinkLongLabel: 'Member Login',
    registerLinkLongLabel: 'Become a Member',
  },

  creditCard: {
    cardName: 'Name On Card',
    cardNumber: 'Card Number',
    cardExpiry: 'Expiry',
    cardCvc: 'Security',
  },

  survey2: {
    heading: 'We Require Some More Information',

    labelOverrides: {
      // This allows titles to be added for specified survey questions per tenant.
      // This empty object makes accessing simpler from templates for tenants that do not use this feature.
    },
    questionTitles: {
      // This allows titles to be added for specified survey questions per tenant.
      // This empty object makes accessing simpler from templates for tenants that do not use this feature.
    },
  },

  membersOnly: {
    title: 'Sorry!',
    description: 'This event is members only',
    loginLinkLabel: 'Log in',
    signupTemplate: 'Not a member? <membership-link>Sign up</membership-link> to enjoy member exclusive events.',
  },

  membersBanner: {
    welcomeBack: 'Welcome back {name}!',
  },

  namedMembers: {
    singleOptionalLabelAnother: 'I would like to name another {role_name}.',
    singleOptionalLabel: (ctx) => {
      const role = ctx.named('role_name') as string
      const article = 'aeiou'.includes(role[0]) ? 'an' : 'a'
      return `I would like to name ${article} ${role}.`
    },
    multipleOptionalLabel: 'How many {role_name} would you like to name?',
    multipleOptionalLabelMore: 'How many more {role_name} would you like to name?',
  },

  unnamedMembers: {
    label: 'Please specify the number of {role_name}.',
    countLabel: '{count} {name} member selected | {count} {name} members selected',
  },

  memberRoleLabels: {
    // TODO Add labels for more common party_roles?
    // The ticket type name is used as a fallback.
    member: 'member | members',
    'second-card': 'second card holder | second card holders',
    'third-card': 'third card holder | third card holders',
    youth: 'youth member | youth members',
    'youth-member': 'youth member | youth members',
    child: 'child member | child members',
    'child-member': 'child member | child members',
    caregiver: 'caregiver | caregivers',
    guest: 'guest | guests',
    'additional-member': 'additional member | additional members',
  },

  membership: {
    title: 'Membership',
    renewTitle: 'Renew Membership',
    secondCard: 'Second member details',
  },

  membershipFormLabels: {
    member: {
      default: 'Your name as you would like it to appear on your membership card.',
      gift: "Recipient's name (as it should appear on the membership card).",
    },
    second_card: {
      default: 'Would you like a second membership card for another adult in your household?',
      gift: "Does a second adult residing in the recipient's household need a membership card?",
    },
    caregiver: {
      default: 'Would you like to add a primary caregiver (e.g. nanny or babysitter) to your account?',
      gift: 'Would you like to add a primary caregiver (e.g. nanny or babysitter) to the account? (if unknown, choose "no")',
    },
    children: {
      default: 'How many children under 18 are in your household?',
      gift: "How many children under 18 are in the recipient's household?",
    },
    physical_card: {
      // default: this.membershipTicketGroup.additional_info_spec.properties.physical_card.title,
      default:
        'Digital membership card(s) will arrive by email. Do you also want physical cards(s) via mail? (Takes 3-5 weeks.)',
      gift: 'Digital membership card(s) can be emailed to the recipient in a later step. Would you like a welcome package with physical membership cards(s) sent by mail? (Arrival may take 3-5 weeks and can’t be scheduled.)',
    },
  },

  giftCards: {
    smallPrintAfterForm: 'Tax-deductible membership gifts and Annual Fund donations are non-refundable.',
  },

  applyGiftCards: {
    modalTitle: 'Apply a Gift Card',
    applyGiftCard: 'Apply a gift card',
    applyAnotherGiftCard: 'Apply another gift card',
  },

  applyGiftCardsModal: {
    giftCardNotFound: 'Gift card not found',
    giftCardNumberLabel: 'Gift Card Number',
  },

  applyPromoCodes: {
    applyButtonLabel: 'Apply',
    applyPromoCodeLabel: 'Apply a promo code',
    applyAnotherPromoCodeLabel: 'Apply another promo code',
    apiErrorMessages: {
      // code_exhausted: 'Code entered has already been redeemed the maximum number of times. Please check the code and reenter.',
    },
    apiErrorMessage: '{message}.',
    defaultApiErrorMessage: '"<em>{code}</em>" is not a valid promo code.',
    unknownErrorMessage: 'Sorry. Please try again later.',
  },

  contextualEventTitles: {
    // Only force a title override for all tenants for renewal and gift of membership.
    membershipGift: 'Gift of Membership',
    membershipRenew: 'Renew Your Membership',
  },

  selectDate: {
    tooltip: 'Select a date',
    placeholder: 'Pick a date',
    buttonTooltip: {
      sold_out: 'Sold out',
      closed: 'Closed',
      unreleased: 'Not released yet',
    },
  },

  ticketTypeSteppers: {
    more: 'More',
    less: 'Less',
    countLabel: '{count} {name} ticket selected | {count} {name} tickets selected',
  },

  price: {
    freePrice: 'Free',
  },

  checkoutRoute: {
    title: 'Payment Information',
    yourInformation: 'Your Information',
    totalOutstanding: 'Total To Pay',
    memberMessage: 'Once you have completed your order, your order will be emailed to {email}.',
    ticketsEmailToMessage: 'Tickets will be emailed to <em>{email}</em> upon payment.',
    identityDataMessage: 'Your {wallet} contact details will be used for this order.',
  },

  identityForm: {
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    emailConfirm: 'Confirm Email Address',
    password: 'Password',
    passwordConfirm: 'Confirm Password',
    state: 'State',
    province: 'Province',
  },

  validationMessages: {
    emailMustMatch: 'Email addresses must match.',
    passwordMustMatch: 'Passwords must match.',
  },

  cartWidget: {
    title: 'Your Cart',
    emptyCartMessage: 'You have no items in your order.',
    subtotal: 'Subtotal',
    payments: 'Total Paid',
    total: 'Order Total',
    discounts: 'Discounts',
    fixedFees: 'Service charges',
    percentFees: 'Tax',
  },

  mobileCartWidget: {
    moreEvents: 'More events',
  },

  expiryTimer: {
    // Use a non-breaking space to avoid line break in the countdown.
    expiresIn: 'Reservation will expire in <span class="time">{time} min</span>',
    screenReaderExpiresIn: 'Reservation will expire in {minutes} minutes and {seconds} seconds',
  },

  payButton: {
    desktop: {
      proceedButtonLabel: 'Proceed to Checkout',
      completeButtonLabel: 'Complete Order',
      completeGiftButtonLabel: 'Complete Gift Order',
      completeRedeemMembershipLabel: 'Redeem Membership',
    },
    mobile: {
      proceedButtonLabel: 'Checkout',
      completeButtonLabel: 'Complete Order',
      completeGiftButtonLabel: 'Complete Gift Order',
      completeRedeemMembershipLabel: 'Redeem Membership',
    },
  },

  termsCheckbox: {
    checkboxLabel: 'Please <a>read and accept the terms & conditions</a> to proceed with your order.',
  },

  cartExpire: {
    title: 'Order Timed Out',
    message: 'Unfortunately your order has timed out.',
    buttonLabel: 'Start Over',
  },

  resendOrderRoute: {
    title: 'Enter your email address to resend your tickets',
    notFound: 'No orders could be found for this email address.',
    logout: 'Log out to resend your tickets.',
    buttonLabel: 'Resend Ticket Email',
    completeTitle: 'Your order confirmation and tickets have been resent.',
  },

  cancelFreeOrderRoute: {
    cancelTicketsTitle: 'Cancel Events and Tickets',
    cancelTicketsSubtext:
      'Please check the events and/or tickets you would like to cancel for your most recent reservation. You will receive an email confirmation upon cancellation.',
    ticketName: '{typeName} ticket {index}',
    noTicketsFound: 'No valid tickets found on this order.',
    noFreeTicketsFound: 'You must contact us to cancel this order.',
    selectAllLabel: 'Select all tickets',
    cancelSelectedLabel: 'Cancel Selected Tickets',
    cancelOrderLabel: 'Cancel Order',
    order: 'Order',
    cancelModalTitle: 'Cancel Tickets',
    cancelModalSubtext:
      'Are you sure you want to cancel 1 ticket from your reservation? | Are you sure you want to cancel {count} tickets from your reservation?',
    orderCancelled: 'You have successfully cancelled your order',
    ticketsCancelled: 'You have successfully cancelled 1 ticket | You have successfully cancelled {count} tickets',
  },

  editOrderRoute: {
    noTicketsFound: 'No valid tickets found on this order.',
    ticketName: '{typeName} ticket {index}',
    cancelOrderLabel: 'Cancel Order',
    orderCancelled:
      'This order has been cancelled. <router-link to="/">Click here</router-link> to purchase more tickets.',
    removeTicketTitle: 'Remove Ticket',
    removeTicketMessage:
      'Are you sure you want to remove <strong>{ticketName}</strong> from the experience <strong>{eventName}</strong>?',
    removeEventTitle: 'Remove Experience',
    removeEventMessage: 'Are you sure you want to remove <strong>{eventName}</strong> from your order?',
    cancelOrderTitle: 'Cancel Order',
    cancelOrderMessage: 'Are you sure you want to cancel your order?',
    rescheduleSuccessMessage:
      'Your order has been successfully changed. You will receive a confirmation email soon with your new itinerary.',
    ticketCancelledSuccessMessage: 'Your ticket has been cancelled.',
  },

  rescheduleRoute: {
    title: 'Reschedule experience',
    description: `Please choose a new date and time for the experience <strong>{name}</strong>,
        currently booked for <strong>{count}</strong> on <strong>{date}</strong> at <strong>{time}</strong>.`,
    costIncreaseWarning: 'The selected session time of {time} will incur an additional cost of {cost}',
  },

  stripeCreditCard: {
    creditCardLabel: 'Card Details',
  },

  termsModal: {
    title: 'Terms & Conditions',
  },

  membershipModal: {
    title: 'Membership details',
    noEmailMessage: 'No email on file',
    membershipNumber: 'Membership number',
    membershipLevel: 'Membership level',
    membershipExpiration: 'Membership expiration',
    renewMembershipButton: 'Renew membership',
    cancelAutoRenewButton: 'Cancel auto-renew',
  },

  membershipLevel: {
    membershipLevel: 'Membership Level',
    showMoreLabel: 'Show all benefits',
  },

  membershipForm: {
    title: 'Edit your membership',
    caption: 'Please edit the details of your membership',
    membershipExpiration: 'Membership Expiration',
    lastMembershipLevel: 'Last Membership Level',
    gift: {
      title: 'Recipient Details',
      caption: "Please edit the details of your recipient's membership",
    },
  },

  membersMenu: {
    membershipDetails: 'Membership Details',
  },

  buyMembership: {
    reserveButton: 'Add Membership to Order',
    yes: 'Yes, renew annually',
    no: 'No',
    autoRenewal: 'Auto Renewal',
    autoRenewalSubtext: 'Automatically renew your membership.',
    deleteCartOrCheckout: {
      title: 'Buy Membership',
      description: 'You need to take action on your current cart before you can buy another membership.',
    },
  },

  membershipRoute: {
    unavailableMessage: 'Memberships are not available at this time.',
    gifteeHeader: {
      title: 'Recipient Contact Information',
    },
  },

  redeemMembershipRoute: {
    queryParam: {
      title: 'Congratulations! You’ve been gifted a membership!',
      description:
        'Looks like someone really likes you! You’ve received a membership to experience great art, big ideas, and courageous conversations that can only be found here.',
    },
    input: {
      title: 'Redeem a gift of membership',
      description:
        'If you have received a gift of membership from someone, please enter the 10-digit code below to start the redemption process.',
      inputHeaderTitle: 'Gift of membership code',
      inputHeaderDescription: 'This is your unique 10-digit code',
      appliedCode: 'Applied code',
    },
    membershipLevels: {
      label: 'Choose a membership',
      description: 'The first membership is your gift, which you may upgrade at your cost if you wish',
    },
    noMembershipsMessage: 'There are no available memberships at this time. Please contact support.',
  },

  cancelAutoRenewRoute: {
    heading: 'Cancel membership auto-renew',
    message: 'Are you sure you want to unenroll your membership from automatic renewal?',
    confirmButton: 'Confirm unenrollment',
    cancelButton: 'Cancel',
    doneButton: 'Done',
    confirmMessage:
      'Your <strong>{level}</strong> membership is successfully unenrolled. Please continue to use your membership until {valid_to}.',
    unEnrolledWarningMessage: 'This membership does not appear to be enrolled in automatic renewal.<br/>Please <login-link>login</login-link> to view the details of your current membership.',
    loggedInUnEnrolledWarningMessage: 'This membership does not appear to be enrolled in automatic renewal.'
  },

  renewMembershipMessage: {
    expiresIn:
      'Your membership expires {expiryDate}. <membership-link renew="true">Renew now</membership-link> to reserve members-only tickets on {ticketDate}.',
    expired:
      'Your membership expired. <membership-link renew="true">Renew now</membership-link> to reserve members-only tickets on {ticketDate}.',
    expiredNoDate:
      'Your membership expired. <membership-link renew="true">Renew now</membership-link> to reserve members-only tickets.',
  },

  selectSeats: {
    findSeats: 'Find Seats',
    changeSelectedSeats: 'Change Selected Seats',
    message: 'Please select {list}',
    singleItem: '{count} seat | {count} seats',
    listItem: '{count} {name} seat | {count} {name} seats',
  },

  codeModal: {
    title: 'A code is required',
    applyButton: 'Apply',
  },
}

export default english
