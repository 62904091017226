
import BuyMembership from '@/components/events/BuyMembership.vue'
import EventDetailRoute from '@/routes/EventDetailRoute.vue'
import { Component, Vue, Watch } from 'vue-property-decorator'
import FormInput2 from '@/components/forms/FormInput2.vue'
import { environment } from '@/helpers/Environment'

const description =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
@Component({
  name: 'ReserveMembershipStory',
  components: { EventDetailRoute, BuyMembership, FormInput2 },
})
export default class extends Vue {
  showAutoRenew = false
  showExtendedMembership = false
  showGifting = false

  event = {
    id: '123',
    category: 'membership',
    meta: {},
    ticketGroups: [
      {
        id: '1',
        name: 'Individual',
        description: description,
        handler: 'membership',
        types: [{ name: 'Primary' }],
        meta: {},
      },
      {
        id: '2',
        name: 'Dual',
        summary: description,
        handler: 'membership',
        types: [{ name: 'Primary' }],
        meta: {},
      },
      {
        id: '3',
        name: 'Family',
        description: description,
        summary: description,
        handler: 'membership',
        types: [{ name: 'Primary' }],
        meta: {},
      },
    ],
  }

  contexts = new Set()
  loading = false

  @Watch('showAutoRenew')
  setAutoRenew(value: boolean) {
    environment.config.ask_to_autorenew = value ? '1' : undefined
  }

  @Watch('showGifting')
  setGifting(value: boolean) {
    if (value) {
      this.contexts = new Set(['gift'])
    } else {
      this.contexts = new Set()
    }
  }

  @Watch('showExtendedMembership')
  setExtendedMembership(value: boolean) {
    if (value) {
      environment.web.extended_membership_option = {
        code: '2year',
        label: 'Sign up for 2 years and save 10%!',
      }
    } else {
      environment.web.extended_membership_option = undefined
    }
  }

  get optionsKey() {
    const options = {
      showAutoRenew: this.showAutoRenew,
      showExtendedMembership: this.showExtendedMembership,
      showGifting: this.showGifting,
    }
    return JSON.stringify(options)
  }
}
